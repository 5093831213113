import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchUserDetails = createAsyncThunk(
  "admin/user-details",
  async ({ userID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.userDetailsURL.replace("{id}", userID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const userDetailsSlice = createSlice({
  name: "user-details",
  initialState: {
    userDetails: {},
    userDetailsFetching: false,
    userDetailsSuccess: false,
    userDetailsError: false,
    userDetailsErrorMessage: "",
  },
  reducers: {
    clearUserDetailsState: (state) => {
      state.userDetailsError = false;
      state.userDetailsSuccess = false;
      state.userDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchUserDetails.fulfilled]: (state, { payload }) => {
      state.userDetails = payload.data;
      state.userDetailsFetching = false;
      state.userDetailsSuccess = true;
      return state;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.userDetailsFetching = false;
      state.userDetailsError = true;
      state.userDetailsErrorMessage = action?.payload;
    },
    [fetchUserDetails.pending]: (state) => {
      state.userDetailsFetching = true;
    },
  },
});
export const { clearUserDetailsState } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
