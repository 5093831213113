import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchCompanyPermissions = createAsyncThunk(
  "admin/company-permission",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companyPermissionsURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companyPermissionDetailsSlice = createSlice({
  name: "company-permission",
  initialState: {
    companyPermissions: {},
    companyPermissionsFetching: false,
    companyPermissionsSuccess: false,
    companyPermissionsError: false,
    companyPermissionsErrorMessage: "",
  },
  reducers: {
    clearCompanyPermissionsState: (state) => {
      state.companyPermissionsError = false;
      state.companyPermissionsSuccess = false;
      state.companyPermissionsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCompanyPermissions.fulfilled]: (state, { payload }) => {
      state.companyPermissions = payload.data;
      state.companyPermissionsFetching = false;
      state.companyPermissionsSuccess = true;
      return state;
    },
    [fetchCompanyPermissions.rejected]: (state, action) => {
      state.companyPermissionsFetching = false;
      state.companyPermissionsError = true;
      state.companyPermissionsErrorMessage = action?.payload;
    },
    [fetchCompanyPermissions.pending]: (state) => {
      state.companyPermissionsFetching = true;
    },
  },
});
export const { clearCompanyPermissionsState } = companyPermissionDetailsSlice.actions;

export default companyPermissionDetailsSlice.reducer;
