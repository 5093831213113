import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchEmployeeDetails = createAsyncThunk(
  "admin/employee-details",
  async ({ employeeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeDetailsURL.replace("{id}", employeeID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeDetailsSlice = createSlice({
  name: "employee-details",
  initialState: {
    employeeDetails: {},
    employeeDetailsFetching: false,
    employeeDetailsSuccess: false,
    employeeDetailsError: false,
    employeeDetailsErrorMessage: "",
  },
  reducers: {
    clearEmployeeDetailsState: (state) => {
      state.employeeDetailsError = false;
      state.employeeDetailsSuccess = false;
      state.employeeDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchEmployeeDetails.fulfilled]: (state, { payload }) => {
      state.employeeDetails = payload.data;
      state.employeeDetailsFetching = false;
      state.employeeDetailsSuccess = true;
      return state;
    },
    [fetchEmployeeDetails.rejected]: (state, action) => {
      state.employeeDetailsFetching = false;
      state.employeeDetailsError = true;
      state.employeeDetailsErrorMessage = action?.payload;
    },
    [fetchEmployeeDetails.pending]: (state) => {
      state.employeeDetailsFetching = true;
    },
  },
});
export const { clearEmployeeDetailsState } = employeeDetailsSlice.actions;

export default employeeDetailsSlice.reducer;
