import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchNormalShiftDetails = createAsyncThunk(
  "admin/normalShift-details",
  async ({ normalShiftID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.normalShiftDetailsURL.replace("{id}", normalShiftID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const normalShiftDetailsSlice = createSlice({
  name: "normalShift-details",
  initialState: {
    normalShiftDetails: {},
    normalShiftDetailsFetching: false,
    normalShiftDetailsSuccess: false,
    normalShiftDetailsError: false,
    normalShiftDetailsErrorMessage: "",
  },
  reducers: {
    clearNormalShiftDetailsState: (state) => {
      state.normalShiftDetailsError = false;
      state.normalShiftDetailsSuccess = false;
      state.normalShiftDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchNormalShiftDetails.fulfilled]: (state, { payload }) => {
      state.normalShiftDetails = payload.data;
      state.normalShiftDetailsFetching = false;
      state.normalShiftDetailsSuccess = true;
      return state;
    },
    [fetchNormalShiftDetails.rejected]: (state, action) => {
      state.normalShiftDetailsFetching = false;
      state.normalShiftDetailsError = true;
      state.normalShiftDetailsErrorMessage = action?.payload;
    },
    [fetchNormalShiftDetails.pending]: (state) => {
      state.normalShiftDetailsFetching = true;
    },
  },
});
export const { clearNormalShiftDetailsState } = normalShiftDetailsSlice.actions;

export default normalShiftDetailsSlice.reducer;
