import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const fetchCompanyDetails = createAsyncThunk(
  "admin/company-details",
  async ({ companyID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companyDetailsURL.replace("{id}", companyID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companyDetailsSlice = createSlice({
  name: "company-details",
  initialState: {
    companyDetails: {},
    companyDetailsFetching: false,
    companyDetailsSuccess: false,
    companyDetailsError: false,
    companyDetailsErrorMessage: "",
  },
  reducers: {
    clearCompanyDetailsState: (state) => {
      state.companyDetailsError = false;
      state.companyDetailsSuccess = false;
      state.companyDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCompanyDetails.fulfilled]: (state, { payload }) => {
      state.companyDetails = payload.data;
      state.companyDetailsFetching = false;
      state.companyDetailsSuccess = true;
      return state;
    },
    [fetchCompanyDetails.rejected]: (state, action) => {
      state.companyDetailsFetching = false;
      state.companyDetailsError = true;
      state.companyDetailsErrorMessage = action?.payload;
    },
    [fetchCompanyDetails.pending]: (state) => {
      state.companyDetailsFetching = true;
    },
  },
});
export const { clearCompanyDetailsState } = companyDetailsSlice.actions;

export default companyDetailsSlice.reducer;
