import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const deleteNormalShift = createAsyncThunk(
  "admin/delete-normalShift",
  async ({ normalShiftID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.normalShiftDetailsURL.replace("{id}", normalShiftID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const normalShiftDeleteSlice = createSlice({
  name: "delete-normalShift",
  initialState: {
    deleteNormalShiftFetching: false,
    deleteNormalShiftSuccess: false,
    deleteNormalShiftError: false,
    deleteNormalShiftErrorMessage: "",
  },
  reducers: {
    deleteNormalShiftClearState: (state) => {
      state.deleteNormalShiftError = false;
      state.deleteNormalShiftSuccess = false;
      state.deleteNormalShiftFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteNormalShift.fulfilled]: (state, { payload }) => {
      state.deleteNormalShiftFetching = false;
      state.deleteNormalShiftSuccess = true;
      return state;
    },
    [deleteNormalShift.rejected]: (state, action) => {
      state.deleteNormalShiftFetching = false;
      state.deleteNormalShiftError = true;
      state.deleteNormalShiftErrorMessage = action?.payload;
    },
    [deleteNormalShift.pending]: (state) => {
      state.deleteNormalShiftFetching = true;
    },
  },
});

export const { deleteNormalShiftClearState } = normalShiftDeleteSlice.actions;

export default normalShiftDeleteSlice.reducer;
