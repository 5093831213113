import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteEmployeeDocumentFile = createAsyncThunk(
  "admin/delete-EmployeeDocumentFile",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeDocumentFileDeleteURL.replace("{id}", id),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const employeeDocumentFileDeleteSlice = createSlice({
  name: "delete-EmployeeDocumentFile",
  initialState: {
    deleteEmployeeDocumentFileFetching: false,
    deleteEmployeeDocumentFileSuccess: false,
    deleteEmployeeDocumentFileError: false,
    deleteEmployeeDocumentFileErrorMessage: "",
  },
  reducers: {
    deleteEmployeeDocumentFileClearState: (state) => {
      state.deleteEmployeeDocumentFileError = false;
      state.deleteEmployeeDocumentFileSuccess = false;
      state.deleteEmployeeDocumentFileFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteEmployeeDocumentFile.fulfilled]: (state, { payload }) => {
      state.deleteEmployeeDocumentFileFetching = false;
      state.deleteEmployeeDocumentFileSuccess = true;
      return state;
    },
    [deleteEmployeeDocumentFile.rejected]: (state, action) => {
      state.deleteEmployeeDocumentFileFetching = false;
      state.deleteEmployeeDocumentFileError = true;
      state.deleteEmployeeDocumentFileErrorMessage = action?.payload;
    },
    [deleteEmployeeDocumentFile.pending]: (state) => {
      state.deleteEmployeeDocumentFileFetching = true;
    },
  },
});

export const { deleteEmployeeDocumentFileClearState } =
  employeeDocumentFileDeleteSlice.actions;

export default employeeDocumentFileDeleteSlice.reducer;
