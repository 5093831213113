import { useState, useContext, memo, Fragment, useEffect } from "react";
//Router
import { Link, useLocation, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
//React-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  const dispatch = useDispatch();

  const [userID, setUserID] = useState(localStorage.getItem("userID"));

  const userType = localStorage.getItem("user-type");

  console.log();
  // Decrypt
  if (userType === "staff") {
    const permission = localStorage.getItem("permissions") || "{}";
    var bytes = CryptoJS.AES.decrypt(permission, "secret key 123");
    var decryptedPermission = bytes.toString(CryptoJS.enc.Utf8);
    var permissions = JSON.parse(decryptedPermission || "{}");
  }else if(userType === "owner"){
    const permission = localStorage.getItem("permissions") || "{}";
    var bytes = CryptoJS.AES.decrypt(permission, "secret key 1234");
    var decryptedPermission = bytes.toString(CryptoJS.enc.Utf8);
    var permissions = JSON.parse(decryptedPermission || "{}");
  }



  const { t } = useTranslation();
  const { active_tab, propertyID } = useParams();
  const [activeMenu, setActiveMenu] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [active, setActive] = useState("");

  //location
  let location = useLocation();

  return (
    <Fragment className="">
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item"></li>
        {(userType === 'owner' || permissions?.dashboard) ? (
          <li
            style={
              location.pathname === "/dashboard"
                ? { background: "#eff8fb", borderRadius: "6px" }
                : {}
            }
            className={`${
              location.pathname === "/dashboard" ? "active" : ""
            } nav-item `}
          >
            <Link
              className={`${
                location.pathname === "/dashboard" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/dashboard"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Dashboard</Tooltip>}
              >
                <i className="icon">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="#05B59B"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M9.71701 0.333252H11.9743C12.9092 0.333252 13.6667 1.09715 13.6667 2.03989V4.31627C13.6667 5.25901 12.9092 6.02291 11.9743 6.02291H9.71701C8.78216 6.02291 8.02466 5.25901 8.02466 4.31627V2.03989C8.02466 1.09715 8.78216 0.333252 9.71701 0.333252Z"
                      fill="#05B59B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.02569 0.333252H4.283C5.21785 0.333252 5.97535 1.09715 5.97535 2.03989V4.31627C5.97535 5.25901 5.21785 6.02291 4.283 6.02291H2.02569C1.09085 6.02291 0.333344 5.25901 0.333344 4.31627V2.03989C0.333344 1.09715 1.09085 0.333252 2.02569 0.333252ZM2.02569 7.97693H4.283C5.21785 7.97693 5.97535 8.74083 5.97535 9.68357V11.9599C5.97535 12.902 5.21785 13.6666 4.283 13.6666H2.02569C1.09085 13.6666 0.333344 12.902 0.333344 11.9599V9.68357C0.333344 8.74083 1.09085 7.97693 2.02569 7.97693ZM11.9743 7.97693H9.71702C8.78217 7.97693 8.02467 8.74083 8.02467 9.68357V11.9599C8.02467 12.902 8.78217 13.6666 9.71702 13.6666H11.9743C12.9092 13.6666 13.6667 12.902 13.6667 11.9599V9.68357C13.6667 8.74083 12.9092 7.97693 11.9743 7.97693Z"
                      fill="#05B59B"
                    />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Dashboard
              </span>
            </Link>
          </li>
        ) : null}

        {/* Employee */}

        {(userType === 'owner' || permissions?.employee )? (
          <li
            style={
              location.pathname === "/employee" ||
              location.pathname.includes("/employee-details")
                ? { background: "#eff8fb", borderRadius: "6px" }
                : {}
            }
            className={`${
              location.pathname === "/employee" ||
              location.pathname.includes("/employee-details")
                ? "active"
                : ""
            } nav-item `}
          >
            <Link
              className={`${
                location.pathname === "/employee" ||
                location.pathname.includes("/employee-details")
                  ? "active"
                  : ""
              } nav-link `}
              aria-current="page"
              to="/employee"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Employee List</Tooltip>}
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#05B59B"
                    class="bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Employee List
              </span>
            </Link>
          </li>
        ) : null}

        {/* Attendance-Reports */}

        {( permissions?.attendance_management || permissions?.attendance) ? (
          <li
            style={
              location.pathname === "/attendance/report" ||
              location.pathname.includes("/attendance")
                ? { background: "#eff8fb", borderRadius: "6px" }
                : {}
            }
            className={`${
              location.pathname === "/attendance" ||
              location.pathname.includes("/attendance")
                ? "active"
                : ""
            } nav-item `}
          >
            <Link
              className={`${
                location.pathname === "/attendance" ||
                location.pathname.includes("/attendance")
                  ? "active"
                  : ""
              } nav-link `}
              aria-current="page"
              to="/attendance/report"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Attendance Report</Tooltip>}
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#05B59B"
                    class="bi bi-file-earmark-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Attendance Report
              </span>
            </Link>
          </li>
        ) : null}
        {/* Document-List */}
        {(permissions?.document_management || permissions?.document )? (
          <li
            style={
              location.pathname === "/document-list" ||
              location.pathname === "/official-documents" ||
              location.pathname === "official-document/{id}/details" ||
              location.pathname === "/project-documents" ||
              location.pathname === "/Project-Documents-Details/{id}" ||
              location.pathname === "/employees-documents" ||
              location.pathname === "/employee-documents-details/{id}"
                ? { background: "#eff8fb", borderRadius: "6px" }
                : {}
            }
            className={`${
              location.pathname === "/document-list" ? "active" : ""
            } nav-item `}
          >
            <Link
              className={`${
                location.pathname === "/document-list" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/document-list"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Document List</Tooltip>}
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#05B59B"
                    class="bi bi-file-earmark-medical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Document List
              </span>
            </Link>
          </li>
        ) : null}

        {/* qr code */}

       {(userType === 'owner' || permissions?.qr_code )? <li
          style={
            location.pathname === "/qr-code"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/qr-code" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/qr-code" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/qr-code"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Qr Code</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-qr-code-scan"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z" />
                  <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z" />
                  <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z" />
                  <path d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z" />
                  <path d="M12 9h2V8h-2v1Z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Qr Code
            </span>
          </Link>
        </li>:null}

        {/* salary-manage-list */}
        {/* <li
          style={
            location.pathname === "/salarymanagelist" ||
            location.pathname === "/salarydetails/{id}"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/salarymanagelist" ||
            location.pathname === "/salarydetails/{id}"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/salarymanagelist" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/salarymanagelist"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Salary Manage List</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-cash"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                  <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Salary Manage List
            </span>
          </Link>
        </li> */}
        {/* Rewards-list */}
        {/* <li
          style={
            location.pathname === "/rewardslist" ||
            location.pathname === "/rewardsdetails/{id}"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/rewardslist" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/rewardslist" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/rewardslist"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Rewards List</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-award-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Rewards List
            </span>
          </Link>
        </li> */}
        {/* Meeting Minits */}
        {/* <li
          style={
            location.pathname === "/meetingminits" ||
            location.pathname === "/sceduled-meeting" ||
            location.pathname === "/upload-minits-list"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/meetingminits" ||
            location.pathname === "/sceduled-meeting"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/meetingminits" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/meetingminits"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Meeting Minutes</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-calendar-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Meeting Minutes
            </span>
          </Link>
        </li> */}
        {/* Events & Announcement */}
        {/* <li
          style={
            location.pathname === "/events-announcement" ||
            location.pathname === "/events-lists" ||
            location.pathname === "/events-details" ||
            location.pathname === "/announcements-list" ||
            location.pathname === "/announcements-details/{id}"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/events-announcement" ||
            location.pathname === "/events-lists" ||
            location.pathname === "/events-lists" ||
            location.pathname === "/announcements-list"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/events-announcement" ||
              location.pathname === "/events-lists" ||
              location.pathname === "/events-lists" ||
              location.pathname === "/announcements-list"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/events-announcement"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Events & Announcement</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-megaphone-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600", fontSize: "13px" }}
            >
              Events & Announcement
            </span>
          </Link>
        </li> */}

        {/* Leave Apply List */}
        {/* <li
          style={
            location.pathname === "/leave-apply-list" ||
            location.pathname === "/leave-details/{id}"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/leave-apply-list" ||
            location.pathname === "/leave-details/{id}"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/leave-apply-list" ||
              location.pathname === "/leave-details/{id}"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/leave-apply-list"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Leave Apply List</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-person-badge-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Leave Apply List
            </span>
          </Link>
        </li> */}
        {/* Complaint Registry */}
        {/* <li
          style={
            location.pathname === "/complaint-registry" ||
            location.pathname === "/complaint-registry/{id}"
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === "/complaint-registry" ||
            location.pathname === "/complaint-registry/{id}"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/complaint-registry" ||
              location.pathname === "/complaint-registry/{id}"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/complaint-registry"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Complaint Registry</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-calendar3"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                  <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Complaint Registry
            </span>
          </Link>
        </li> */}

        {/* User Lsit */}
        {(userType === 'owner' || permissions?.user_management) ? (
          <li
            style={
              location.pathname === "/user-list"
                ? { background: "#eff8fb", borderRadius: "6px" }
                : {}
            }
            className={`${
              location.pathname === "/user-list" ? "active" : ""
            } nav-item `}
          >
            <Link
              className={`${
                location.pathname === "/user-list" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/user-list"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>User List</Tooltip>}
              >
                <i className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#05B59B"
                    class="bi bi-person-lines-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                  </svg>
                </i>
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                User List
              </span>
            </Link>
          </li>
        ) : null}
        {(userType === 'owner' || permissions?.settings )?<li
          style={
            location.pathname === `/settings/${active_tab}`
              ? { background: "#eff8fb", borderRadius: "6px" }
              : {}
          }
          className={`${
            location.pathname === `/settings/${active_tab}` ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === `/settings/${active_tab}` ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/settings/business-details"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Settings</Tooltip>}
            >
              <i className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#05B59B"
                  class="bi bi-gear-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </svg>
              </i>
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Settings
            </span>
          </Link>
        </li>:null}
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
