import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const flexibleTimeScheduleCreate = createAsyncThunk(
  "admin/create-flexibleTimeSchedule",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleTimeSchedulePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleTimeScheduleCreateSlice = createSlice({
  name: "create-flexibleTimeSchedule",
  initialState: {
    flexibleTimeScheduleCreateFetching: false,
    flexibleTimeScheduleCreateSuccess: false,
    flexibleTimeScheduleCreateError: false,
    flexibleTimeScheduleCreateErrorMessage: "",
  },
  reducers: {
    clearCreateFlexibleTimeScheduleState: (state) => {
      state.flexibleTimeScheduleCreateError = false;
      state.flexibleTimeScheduleCreateSuccess = false;
      state.flexibleTimeScheduleCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [flexibleTimeScheduleCreate.fulfilled]: (state, { payload }) => {
      state.flexibleTimeScheduleCreateFetching = false;
      state.flexibleTimeScheduleCreateSuccess = true;
      return state;
    },
    [flexibleTimeScheduleCreate.rejected]: (state, action) => {
      state.flexibleTimeScheduleCreateFetching = false;
      state.flexibleTimeScheduleCreateError = true;
      state.flexibleTimeScheduleCreateErrorMessage = action?.payload;
    },
    [flexibleTimeScheduleCreate.pending]: (state) => {
      state.flexibleTimeScheduleCreateFetching = true;
    },
  },
});

export const { clearCreateFlexibleTimeScheduleState } = flexibleTimeScheduleCreateSlice.actions;

export default flexibleTimeScheduleCreateSlice.reducer;
