import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchPresentTodayList = createAsyncThunk(
  "admin/present-today-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.presentTodayURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const presentTodaySlice = createSlice({
  name: "present-today-list",
  initialState: {
    presentTodayList: [],
    presentTodayListFetching: false,
    presentTodayListSuccess: false,
    presentTodayListError: false,
    presentTodayListErrorMessage: "",
    presentTodayCount:null,
  },
  reducers: {
    presentTodayListClearState: (state) => {
      state.presentTodayListError = false;
      state.presentTodayListSuccess = false;
      state.presentTodayListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchPresentTodayList.fulfilled]: (state, { payload }) => {
      state.presentTodayList = [];
      payload.data.results.forEach((item) => state.presentTodayList.push(item));
      state.presentTodayCount = payload.data.count;
      state.presentTodayListFetching = false;
      state.presentTodayListSuccess = true;
      return state;
    },
    [fetchPresentTodayList.rejected]: (state, action) => {
      state.presentTodayListFetching = false;
      state.presentTodayListError = true;
      state.presentTodayListErrorMessage = action?.payload;
    },
    [fetchPresentTodayList.pending]: (state) => {
      state.presentTodayListFetching = true;
    },
  },
});

export const { presentTodayListClearState } = presentTodaySlice.actions;

export default presentTodaySlice.reducer;
