import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const attendanceRecalculate = createAsyncThunk(
  "admin/recalculate-attendance",
  async ({payload ,attendanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.recalculateAttendanceURL.replace("{id}", attendanceID),
        method: "POST",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceRecalculateSlice = createSlice({
  name: "recalculate-attendance",
  initialState: {
    recalculateAttendanceFetching: false,
    recalculateAttendanceSuccess: false,
    recalculateAttendanceError: false,
    recalculateAttendanceErrorMessage: "",
    recalculateDetails: {},
  },
  reducers: {
    clearRecalculateAttendanceState: (state) => {
      state.recalculateAttendanceError = false;
      state.recalculateAttendanceSuccess = false;
      state.recalculateAttendanceFetching = false;
      return state;
    },
  },
  extraReducers: {
    [attendanceRecalculate.fulfilled]: (state, { payload }) => {
      state.recalculateDetails = payload?.data;
      state.recalculateAttendanceFetching = false;
      state.recalculateAttendanceSuccess = true;
      return state;
    },
    [attendanceRecalculate.rejected]: (state, action) => {
      state.recalculateAttendanceFetching = false;
      state.recalculateAttendanceError = true;
      state.recalculateAttendanceErrorMessage = action?.payload;
    },
    [attendanceRecalculate.pending]: (state) => {
      state.recalculateAttendanceFetching = true;
    },
  },
});
export const { clearRecalculateAttendanceState } = attendanceRecalculateSlice.actions;

export default attendanceRecalculateSlice.reducer;
