import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const editNormalTimeSchedule = createAsyncThunk(
  "admin/edit-normalTimeSchedule",
  async ({payload, normalScheduleID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.normalTimeScheduleDetailsURL.replace("{id}", normalScheduleID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const normalTimeScheduleEditSlice = createSlice({
  name: "edit-normalTimeSchedule",
  initialState: {
    editNormalTimeScheduleFetching: false,
    editNormalTimeScheduleSuccess: false,
    editNormalTimeScheduleError: false,
    editNormalTimeScheduleErrorMessage: "",
  },
  reducers: {
    clearEditNormalTimeScheduleState: (state) => {
      state.editNormalTimeScheduleError = false;
      state.editNormalTimeScheduleSuccess = false;
      state.editNormalTimeScheduleFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editNormalTimeSchedule.fulfilled]: (state, { payload }) => {
      state.editNormalTimeScheduleFetching = false;
      state.editNormalTimeScheduleSuccess = true;
      return state;
    },
    [editNormalTimeSchedule.rejected]: (state, action) => {
      state.editNormalTimeScheduleFetching = false;
      state.editNormalTimeScheduleError = true;
      state.editNormalTimeScheduleErrorMessage = action?.payload;
    },
    [editNormalTimeSchedule.pending]: (state) => {
      state.editNormalTimeScheduleFetching = true;
    },
  },
});
export const { clearEditNormalTimeScheduleState } = normalTimeScheduleEditSlice.actions;

export default normalTimeScheduleEditSlice.reducer;
