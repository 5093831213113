import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchFlexibleShiftDetails = createAsyncThunk(
  "admin/flexibleShift-details",
  async ({ flexibleShiftID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleShiftDetailsURL.replace("{id}", flexibleShiftID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleShiftDetailsSlice = createSlice({
  name: "flexibleShift-details",
  initialState: {
    flexibleShiftDetails: {},
    flexibleShiftDetailsFetching: false,
    flexibleShiftDetailsSuccess: false,
    flexibleShiftDetailsError: false,
    flexibleShiftDetailsErrorMessage: "",
  },
  reducers: {
    clearFlexibleShiftDetailsState: (state) => {
      state.flexibleShiftDetailsError = false;
      state.flexibleShiftDetailsSuccess = false;
      state.flexibleShiftDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFlexibleShiftDetails.fulfilled]: (state, { payload }) => {
      state.flexibleShiftDetails = payload.data;
      state.flexibleShiftDetailsFetching = false;
      state.flexibleShiftDetailsSuccess = true;
      return state;
    },
    [fetchFlexibleShiftDetails.rejected]: (state, action) => {
      state.flexibleShiftDetailsFetching = false;
      state.flexibleShiftDetailsError = true;
      state.flexibleShiftDetailsErrorMessage = action?.payload;
    },
    [fetchFlexibleShiftDetails.pending]: (state) => {
      state.flexibleShiftDetailsFetching = true;
    },
  },
});
export const { clearFlexibleShiftDetailsState } = flexibleShiftDetailsSlice.actions;

export default flexibleShiftDetailsSlice.reducer;
