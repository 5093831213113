import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteEmployeeDocument = createAsyncThunk(
  "admin/delete-employeeDocument",
  async ({ employeeID, documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeDocumentsDetailsURL
          .replace("{empid}", employeeID)
          .replace("{docid}", documentID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const employeeDocumentDeleteSlice = createSlice({
  name: "delete-employeeDocument",
  initialState: {
    deleteEmployeeDocumentFetching: false,
    deleteEmployeeDocumentSuccess: false,
    deleteEmployeeDocumentError: false,
    deleteEmployeeDocumentErrorMessage: "",
  },
  reducers: {
    deleteEmployeeDocumentClearState: (state) => {
      state.deleteEmployeeDocumentError = false;
      state.deleteEmployeeDocumentSuccess = false;
      state.deleteEmployeeDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteEmployeeDocument.fulfilled]: (state, { payload }) => {
      state.deleteEmployeeDocumentFetching = false;
      state.deleteEmployeeDocumentSuccess = true;
      return state;
    },
    [deleteEmployeeDocument.rejected]: (state, action) => {
      state.deleteEmployeeDocumentFetching = false;
      state.deleteEmployeeDocumentError = true;
      state.deleteEmployeeDocumentErrorMessage = action?.payload;
    },
    [deleteEmployeeDocument.pending]: (state) => {
      state.deleteEmployeeDocumentFetching = true;
    },
  },
});

export const { deleteEmployeeDocumentClearState } =
  employeeDocumentDeleteSlice.actions;

export default employeeDocumentDeleteSlice.reducer;
