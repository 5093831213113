import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

//Login
import userSlice from "./login/loginSlice";
import forgotPasswordSlice from "./login/forgotPasswordSlice";
import sendOtpSlice from "./login/sendOtpSlice";
import verifyOtpSlice from "./login/verifyOtpSlice";
import resetPasswordSlice from "./login/resetPasswordSlice";

//Dashboard
import expiringDocumentListSlice from "./dashboard/expiringDocumentListSlice";
import pendingLeaveListSlice from "./dashboard/pendingLeaveListSlice";
import attendanceDataSlice from "./dashboard/attendanceDataSlice";
import dashboardDataSlice from "./admin/dashboard/dashboardDataSlice";
import absentTodaySlice from "./dashboard/absentTodaySlice";
import presentTodaySlice from "./dashboard/presentTodaySlice";
import lateTodaySlice from "./dashboard/lateTodaySlice";

//Common
import countryListSlice from "./misc/countryListSlice";
import adminCountryListSlice from "./misc/adminCountryListSlice";
import timeZoneListSlice from "./misc/timeZoneListSlice";

//Employees
import employeeListSlice from "./employees/employeeListSlice";
import employeeCreateSlice from "./employees/employeeCreateSlice";
import employeeDetailsSlice from "./employees/employeeDetailsSlice";
import employeeEditSlice from "./employees/employeeEditSlice";
import employeeDeleteSlice from "./employees/employeeDeleteSlice";
import autogenerateEmpIdSlice from "./employees/autogenerateEmpIdSlice";
import resetEmployeePasswordSlice from "./employees/resetEmployeePasswordSlice";

//Owner Company
import ownerCompanyListSlice from "./company/ownerCompanyListSlice";
import switchCompanySlice from "./company/switchCompanySlice";
import companyPermissionDetailsSlice from "./company/companyPermissionDetailsSlice";

//Staffs
import staffListSlice from "./staff/staffListSlice";
import staffCreateSlice from "./staff/staffCreateSlice";
import staffDetailsSlice from "./staff/staffDetailsSlice";
import staffEditSlice from "./staff/staffEditSlice";
import staffDeleteSlice from "./staff/staffDeleteSlice";

//Attendance Reports
import attendanceReportSlice from "./attendanceReports/attendanceReportSlice";
import attendanceSummarySlice from "./attendanceReports/attendanceSummarySlice";
import attendanceDetailsSlice from "./attendanceReports/attendanceDetailsSlice";
import attendanceEditSlice from "./attendanceReports/attendanceEditSlice";
import attendanceRecalculateSlice from "./attendanceReports/attendanceRecalculateSlice";
import attendanceDetailReportSlice from "./attendanceReports/attendanceDetailReportSlice";
import attendanceDailyReportSlice from "./attendanceReports/attendanceDailyReportSlice";
import lateReportSlice from "./attendanceReports/lateReportSlice";
import bulkAttendanceCreateSlice from "./employees/bulkAttendanceCreateSlice";

//Employee Documents
import employeeDocumentListSlice from "./documents/employeesDocuments/employeeDocumentListSlice";
import employeeDocumentDetailsSlice from "./documents/employeesDocuments/employeeDocumentDetailsSlice";
import employeeDocumentCreateSlice from "./documents/employeesDocuments/employeeDocumentCreateSlice";
import employeeDocumentDeleteSlice from "./documents/employeesDocuments/employeeDocumentDeleteSlice";
import employeeDocumentEditSlice from "./documents/employeesDocuments/employeeDocumentEditSlice";
import employeeDocumentFileDeleteSlice from "./documents/employeesDocuments/employeeDocumentFileDeleteSlice";

//Company Documents
import officialDocumentListSlice from "./documents/companyDocuments/officialDocuments/officialDocumentListSlice";
import officialDocumentDetailsSlice from "./documents/companyDocuments/officialDocuments/officialDocumentDetailsSlice";
import officialDocumentCreateSlice from "./documents/companyDocuments/officialDocuments/officialDocumentCreateSlice";
import officialDocumentEditSlice from "./documents/companyDocuments/officialDocuments/officialDocumentEditSlice";
import officialDocumentDeleteSlice from "./documents/companyDocuments/officialDocuments/officialDocumentDeleteSlice";
import officialDocumentFileDeleteSlice from "./documents/companyDocuments/officialDocuments/officialDocumentFileDeleteSlice";

//Time Schedule
import normalTimeScheduleCreateSlice from "./companySetting/timeSchedule/normal/normalTimeScheduleCreateSlice";
import normalTimeScheduleListSlice from "./companySetting/timeSchedule/normal/normalTimeScheduleListSlice";
import normalTimeScheduleDetailsSlice from "./companySetting/timeSchedule/normal/normalTimeScheduleDetailsSlice";
import normalTimeScheduleEditSlice from "./companySetting/timeSchedule/normal/normalTimeScheduleEditSlice";
import normalTimeScheduleDeleteSlice from "./companySetting/timeSchedule/normal/normalTimeScheduleDeleteSlice";

import flexibleTimeScheduleCreateSlice from "./companySetting/timeSchedule/flexible/flexibleTimeScheduleCreateSlice";
import flexibleTimeScheduleListSlice from "./companySetting/timeSchedule/flexible/flexibleTimeScheduleListSlice";
import flexibleTimeScheduleDetailsSlice from "./companySetting/timeSchedule/flexible/flexibleTimeScheduleDetailsSlice";
import flexibleTimeScheduleEditSlice from "./companySetting/timeSchedule/flexible/flexibleTimeScheduleEditSlice";
import flexibleTimeScheduleDeleteSlice from "./companySetting/timeSchedule/flexible/flexibleTimeScheduleDeleteSlice";

//Shifts
import normalShiftCreateSlice from "./companySetting/shifts/normal/normalShiftCreateSlice";
import normalShiftListSlice from "./companySetting/shifts/normal/normalShiftListSlice";
import normalShiftDetailsSlice from "./companySetting/shifts/normal/normalShiftDetailsSlice";
import normalShiftEditSlice from "./companySetting/shifts/normal/normalShiftEditSlice";
import normalShiftDeleteSlice from "./companySetting/shifts/normal/normalShiftDeleteSlice";

import flexibleShiftCreateSlice from "./companySetting/shifts/flexible/flexibleShiftCreateSlice";
import flexibleShiftListSlice from "./companySetting/shifts/flexible/flexibleShiftListSlice";
import flexibleShiftDetailsSlice from "./companySetting/shifts/flexible/flexibleShiftDetailsSlice";
import flexibleShiftEditSlice from "./companySetting/shifts/flexible/flexibleShiftEditSlice";
import flexibleShiftDeleteSlice from "./companySetting/shifts/flexible/flexibleShiftDeleteSlice";

//Holidays
import holidayListSlice from "./companySetting/holidays/holidayListSlice";
import holidayCreateSlice from "./companySetting/holidays/holidayCreateSlice";
import holidayDetailsSlice from "./companySetting/holidays/holidayDetailsSlice";
import holidayEditSlice from "./companySetting/holidays/holidayEditSlice";
import holidayDeleteSlice from "./companySetting/holidays/holidayDeleteSlice";

//Departments
import departmentCreateSlice from "./companySetting/departments/departmentCreateSlice";
import departmentListSlice from "./companySetting/departments/departmentListSlice";
import departmentDetailsSlice from "./companySetting/departments/departmentDetailsSlice";
import departmentEditSlice from "./companySetting/departments/departmentEditSlice";
import departmentDeleteSlice from "./companySetting/departments/departmentDeleteSlice";

//Employee Id
import employeeIDDetailsSlice from "./employeeId/employeeIDDetailsSlice";
import employeeIDDetailsEditSlice from "./employeeId/employeeIDDetailsEditSlice";

//Owners
import ownerListSlice from "./admin/owners/ownerListSlice";
import ownerCreateSlice from "./admin/owners/ownerCreateSlice";
import ownerDetailsSlice from "./admin/owners/ownerDetailsSlice";
import ownerEditSlice from "./admin/owners/ownerEditSlice";
import ownerDeleteSlice from "./admin/owners/ownerDeleteSlice";

//Users
import userListSlice from "./admin/users/userListSlice";
import userCreateSlice from "./admin/users/userCreateSlice";
import userDetailsSlice from "./admin/users/userDetailsSlice";
import userEditSlice from "./admin/users/userEditSlice";
import userDeleteSlice from "./admin/users/userDeleteSlice";

//Companies
import companyListSlice from "./admin/companies/companyListSlice";
import companyCreateSlice from "./admin/companies/companyCreateSlice";
import companyDetailsSlice from "./admin/companies/companyDetailsSlice";
import companyEditSlice from "./admin/companies/companyEditSlice";
import companyDeleteSlice from "./admin/companies/companyDeleteSlice";

//Company Subscriptions
import companySubscriptionListSlice from "./admin/companies/subscriptions/companySubscriptionListSlice";
import companySubscriptionCreateSlice from "./admin/companies/subscriptions/companySubscriptionCreateSlice";
import companySubscriptionDetailsSlice from "./admin/companies/subscriptions/companySubscriptionDetailsSlice";
import companySubscriptionEditSlice from "./admin/companies/subscriptions/companySubscriptionEditSlice";

//Subscription Plans
import subscriptionPlanCreateSlice from "./admin/subscriptionPlans/subscriptionPlanCreateSlice";
import subscriptionPlanListSlice from "./admin/subscriptionPlans/subscriptionPlanListSlice";
import subscriptionPlanDetailsSlice from "./admin/subscriptionPlans/subscriptionPlanDetailsSlice";
import subscriptionPlanEditSlice from "./admin/subscriptionPlans/subscriptionPlanEditSlice";
import subscriptionPlanDeleteSlice from "./admin/subscriptionPlans/subscriptionPlanDeleteSlice";

//QR Codes
import qrCodeListSlice from "./qrCodes/qrCodeListSlice";
import qrCodeCreateSlice from "./qrCodes/qrCodeCreateSlice";
import qrCodeDetailsSlice from "./qrCodes/qrCodeDetailsSlice";
import qrCodeEditSlice from "./qrCodes/qrCodeEditSlice";
import qrCodeDeleteSlice from "./qrCodes/qrCodeDeleteSlice";

//Categories
import categoryCreateSlice from "./admin/admin-settings/categories/categoryCreateSlice";
import categoryListSlice from "./admin/admin-settings/categories/categoryListSlice";
import categoryDeleteSlice from "./admin/admin-settings/categories/categoryDeleteSlice";
import categoryDetailsSlice from "./admin/admin-settings/categories/categoryDetailsSlice";
import categoryEditSlice from "./admin/admin-settings/categories/categoryEditSlice";

//project document
import projectDocumentListSlice from "./documents/projectDocuments/projectDocumentsListSlice";
import projectDocumentCreateSlice from "./documents/projectDocuments/projectDocumentCreateSlice";
import ProjectDocumentDetailsSlice from "./documents/projectDocuments/ProjectDocumentDetailsSlice";
import ProjectDocumentDeleteSlice from "./documents/projectDocuments/projectDocumentDeleteSlice";
import ProjectDocumentEditSlice from "./documents/projectDocuments/projectDocumentEditSlice";
import projectDocumentFileDeleteSlice from "./documents/projectDocuments/projectDocumentFileDeleteSlice";

//vehicle document
import vehicleDocumentListSlice from "./documents/VehicleDocuments/vehicleDocumentsListSlice";
import vehicleDocumentCreateSlice from "./documents/VehicleDocuments/vehicleDocumentCreateSlice";
import vehicleDocumentDetailsListSlice from "./documents/VehicleDocuments/vehicleDocumentDetailsListSlice";
import vehicleListSlice from "./documents/VehicleDocuments/vehicleListSlice";
import AddVehicleAndUploadSlice from "./documents/VehicleDocuments/vehicleDocumentUploadSlice";
import VehicleDocumentEditSlice from "./documents/VehicleDocuments/vehicleDocumentEditSlice";
import VehicleDocumentDeleteSlice from "./documents/VehicleDocuments/vehicleDocumentDeleteSlice";
import vehicleDocumentFileDeleteSlice from "./documents/VehicleDocuments/vehicleDocumentFileDeleteSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    forgotPasswordSlice,
    sendOtpSlice,
    verifyOtpSlice,
    resetPasswordSlice,

    //Dashboard
    expiringDocumentListSlice,
    pendingLeaveListSlice,
    attendanceDataSlice,
    dashboardDataSlice,
    presentTodaySlice,
    absentTodaySlice,
    lateTodaySlice,

    //Common
    countryListSlice,
    adminCountryListSlice,
    timeZoneListSlice,

    //Employees
    employeeListSlice,
    employeeCreateSlice,
    employeeDetailsSlice,
    employeeEditSlice,
    employeeDeleteSlice,
    autogenerateEmpIdSlice,
    resetEmployeePasswordSlice,

    //Owner Company
    ownerCompanyListSlice,
    switchCompanySlice,
    companyPermissionDetailsSlice,

    //Staffs
    staffListSlice,
    staffCreateSlice,
    staffDetailsSlice,
    staffEditSlice,
    staffDeleteSlice,

    //Attendance Reports
    attendanceReportSlice,
    attendanceSummarySlice,
    attendanceDetailsSlice,
    attendanceEditSlice,
    attendanceRecalculateSlice,
    attendanceDetailReportSlice,
    attendanceDailyReportSlice,
    lateReportSlice,
    bulkAttendanceCreateSlice,

    //Employee Documents
    employeeDocumentListSlice,
    employeeDocumentDetailsSlice,
    employeeDocumentCreateSlice,
    employeeDocumentDeleteSlice,
    employeeDocumentEditSlice,
    employeeDocumentFileDeleteSlice,

    //Company Documents
    officialDocumentListSlice,
    officialDocumentDetailsSlice,
    officialDocumentCreateSlice,
    officialDocumentEditSlice,
    officialDocumentDeleteSlice,
    officialDocumentFileDeleteSlice,

    //QR Codes
    qrCodeListSlice,
    qrCodeCreateSlice,
    qrCodeDetailsSlice,
    qrCodeEditSlice,
    qrCodeDeleteSlice,

    //Time Schedule
    normalTimeScheduleCreateSlice,
    normalTimeScheduleListSlice,
    normalTimeScheduleDetailsSlice,
    normalTimeScheduleEditSlice,
    normalTimeScheduleDeleteSlice,

    flexibleTimeScheduleCreateSlice,
    flexibleTimeScheduleListSlice,
    flexibleTimeScheduleDetailsSlice,
    flexibleTimeScheduleEditSlice,
    flexibleTimeScheduleDeleteSlice,

    //Shifts
    normalShiftCreateSlice,
    normalShiftListSlice,
    normalShiftDetailsSlice,
    normalShiftEditSlice,
    normalShiftDeleteSlice,

    flexibleShiftCreateSlice,
    flexibleShiftListSlice,
    flexibleShiftDetailsSlice,
    flexibleShiftEditSlice,
    flexibleShiftDeleteSlice,

    //Holydays
    holidayListSlice,
    holidayCreateSlice,
    holidayDetailsSlice,
    holidayEditSlice,
    holidayDeleteSlice,

    //Departments
    departmentCreateSlice,
    departmentListSlice,
    departmentDetailsSlice,
    departmentEditSlice,
    departmentDeleteSlice,

    //Employee Id
    employeeIDDetailsSlice,
    employeeIDDetailsEditSlice,

    //Owners
    ownerListSlice,
    ownerCreateSlice,
    ownerDetailsSlice,
    ownerEditSlice,
    ownerDeleteSlice,

    //Users
    userListSlice,
    userCreateSlice,
    userDetailsSlice,
    userEditSlice,
    userDeleteSlice,

    //Companies
    companyListSlice,
    companyCreateSlice,
    companyDetailsSlice,
    companyEditSlice,
    companyDeleteSlice,

    //Company Subscriptions
    companySubscriptionListSlice,
    companySubscriptionCreateSlice,
    companySubscriptionDetailsSlice,
    companySubscriptionEditSlice,

    //Subscription Plans
    subscriptionPlanCreateSlice,
    subscriptionPlanListSlice,
    subscriptionPlanDetailsSlice,
    subscriptionPlanEditSlice,
    subscriptionPlanDeleteSlice,

    //Categories
    categoryCreateSlice,
    categoryListSlice,
    categoryDeleteSlice,
    categoryDetailsSlice,
    categoryEditSlice,

    // project document
    projectDocumentListSlice,
    projectDocumentCreateSlice,
    ProjectDocumentDetailsSlice,
    ProjectDocumentDeleteSlice,
    ProjectDocumentEditSlice,
    projectDocumentFileDeleteSlice,

    // vehicle document
    vehicleDocumentListSlice,
    vehicleDocumentCreateSlice,
    vehicleDocumentDetailsListSlice,
    vehicleListSlice,
    AddVehicleAndUploadSlice,
    VehicleDocumentEditSlice,
    VehicleDocumentDeleteSlice,
    vehicleDocumentFileDeleteSlice,
  },
});
