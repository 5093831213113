import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchHolidaysList = createAsyncThunk(
  "admin/holiday-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.holidaysListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const holidayListSlice = createSlice({
  name: "holiday-list",
  initialState: {
    holidayList: [],
    holidayListFetching: false,
    holidayListSuccess: false,
    holidayListError: false,
    holidayListErrorMessage: "",
    holidayCount:null,
  },
  reducers: {
    holidayListClearState: (state) => {
      state.holidayListError = false;
      state.holidayListSuccess = false;
      state.holidayListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchHolidaysList.fulfilled]: (state, { payload }) => {
      state.holidayList = [];
      payload.data.results.forEach((item) => state.holidayList.push(item));
      state.holidayCount = payload.data.count;
      state.holidayListFetching = false;
      state.holidayListSuccess = true;
      return state;
    },
    [fetchHolidaysList.rejected]: (state, action) => {
      state.holidayListFetching = false;
      state.holidayListError = true;
      state.holidayListErrorMessage = action?.payload;
    },
    [fetchHolidaysList.pending]: (state) => {
      state.holidayListFetching = true;
    },
  },
});

export const { holidayListClearState } = holidayListSlice.actions;

export default holidayListSlice.reducer;
