import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchOwnersList = createAsyncThunk(
  "admin/owner-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.ownersListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const ownerListSlice = createSlice({
  name: "owner-list",
  initialState: {
    ownerList: [],
    ownerListFetching: false,
    ownerListSuccess: false,
    ownerListError: false,
    ownerListErrorMessage: "",
    ownerCount:null,
  },
  reducers: {
    ownerListClearState: (state) => {
      state.ownerListError = false;
      state.ownerListSuccess = false;
      state.ownerListFetching = false;
      return state;
    },
  },
  extraReducers: {
    [fetchOwnersList.fulfilled]: (state, { payload }) => {
      state.ownerList = [];
      payload.data.results.forEach((item) => state.ownerList.push(item));
      state.ownerCount = payload.data.count;
      state.ownerListFetching = false;
      state.ownerListSuccess = true;
      return state;
    },
    [fetchOwnersList.rejected]: (state, action) => {
      state.ownerListFetching = false;
      state.ownerListError = true;
      state.ownerListErrorMessage = action?.payload;
    },
    [fetchOwnersList.pending]: (state) => {
      state.ownerListFetching = true;
    },
  },
});

export const { ownerListClearState } = ownerListSlice.actions;

export default ownerListSlice.reducer;
