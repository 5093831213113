import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const companyCreate = createAsyncThunk(
  "admin/create-company",
  async ({ payload ,ownerID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companyPostURL.replace("{id}", ownerID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companyCreateSlice = createSlice({
  name: "create-company",
  initialState: {
    companyCreateFetching: false,
    companyCreateSuccess: false,
    companyCreateError: false,
    companyCreateErrorMessage: "",
  },
  reducers: {
    clearCreateCompanyState: (state) => {
      state.companyCreateError = false;
      state.companyCreateSuccess = false;
      state.companyCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [companyCreate.fulfilled]: (state, { payload }) => {
      state.companyCreateFetching = false;
      state.companyCreateSuccess = true;
      return state;
    },
    [companyCreate.rejected]: (state, action) => {
      state.companyCreateFetching = false;
      state.companyCreateError = true;
      state.companyCreateErrorMessage = action?.payload;
    },
    [companyCreate.pending]: (state) => {
      state.companyCreateFetching = true;
    },
  },
});

export const { clearCreateCompanyState } = companyCreateSlice.actions;

export default companyCreateSlice.reducer;
