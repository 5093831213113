import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAttendanceDailyReport = createAsyncThunk(
  "admin/attendance-daily-report",
  async (
    { limit, offset, date, nationality, department, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url: api.dailyAttendanceURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{date}", date)
          .replace("{search}", search)
          .replace("{nationality}", nationality)
          .replace("{department}", department),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceDailyReportSlice = createSlice({
  name: "attendance-daily-report",
  initialState: {
    attendanceDailyReport: [],
    attendanceDailyReportFetching: false,
    attendanceDailyReportSuccess: false,
    attendanceDailyReportError: false,
    attendanceDailyReportErrorMessage: "",
    attendanceDailyReportCount: null,
  },
  reducers: {
    attendanceDailyReportClearState: (state) => {
      state.attendanceDailyReportError = false;
      state.attendanceDailyReportSuccess = false;
      state.attendanceDailyReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAttendanceDailyReport.fulfilled]: (state, { payload }) => {
      state.attendanceDailyReport = [];
      payload.data.results.forEach((item) => state.attendanceDailyReport.push(item));
      state.attendanceDailyReportCount = payload.data.count;
      state.attendanceDailyReportFetching = false;
      state.attendanceDailyReportSuccess = true;
      return state;
    },
    [fetchAttendanceDailyReport.rejected]: (state, action) => {
      state.attendanceDailyReportFetching = false;
      state.attendanceDailyReportError = true;
      state.attendanceDailyReportErrorMessage = action?.payload;
    },
    [fetchAttendanceDailyReport.pending]: (state) => {
      state.attendanceDailyReportFetching = true;
    },
  },
});

export const { attendanceDailyReportClearState } = attendanceDailyReportSlice.actions;

export default attendanceDailyReportSlice.reducer;
