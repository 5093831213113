import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";


export const editCategoryDetails = createAsyncThunk(
  "admin/edit-category",
  async ({payload, categoryID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.categoryDetailsURL.replace("{id}", categoryID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const categoryEditSlice = createSlice({
  name: "edit-category",
  initialState: {
    editCategoryFetching: false,
    editCategorySuccess: false,
    editCategoryError: false,
    editCategoryErrorMessage: "",
  },
  reducers: {
    clearEditCategoryState: (state) => {
      state.editCategoryError = false;
      state.editCategorySuccess = false;
      state.editCategoryFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editCategoryDetails.fulfilled]: (state, { payload }) => {
      state.editCategoryFetching = false;
      state.editCategorySuccess = true;
      return state;
    },
    [editCategoryDetails.rejected]: (state, action) => {
      state.editCategoryFetching = false;
      state.editCategoryError = true;
      state.editCategoryErrorMessage = action?.payload;
    },
    [editCategoryDetails.pending]: (state) => {
      state.editCategoryFetching = true;
    },
  },
});
export const { clearEditCategoryState } = categoryEditSlice.actions;

export default categoryEditSlice.reducer;
