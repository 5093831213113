import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchCompanySubscriptionDetails = createAsyncThunk(
  "admin/company-subscription-details",
  async ({ subscriptionID ,companyID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companySubscriptionDetailsURL.replace("{company_id}", companyID).replace("{id}", subscriptionID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companySubscriptionDetailsSlice = createSlice({
  name: "company-subscription-details",
  initialState: {
    companySubscriptionDetails: {},
    companySubscriptionDetailsFetching: false,
    companySubscriptionDetailsSuccess: false,
    companySubscriptionDetailsError: false,
    companySubscriptionDetailsErrorMessage: "",
  },
  reducers: {
    clearCompanySubscriptionDetailsState: (state) => {
      state.companySubscriptionDetailsError = false;
      state.companySubscriptionDetailsSuccess = false;
      state.companySubscriptionDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCompanySubscriptionDetails.fulfilled]: (state, { payload }) => {
      state.companySubscriptionDetails = payload.data;
      state.companySubscriptionDetailsFetching = false;
      state.companySubscriptionDetailsSuccess = true;
      return state;
    },
    [fetchCompanySubscriptionDetails.rejected]: (state, action) => {
      state.companySubscriptionDetailsFetching = false;
      state.companySubscriptionDetailsError = true;
      state.companySubscriptionDetailsErrorMessage = action?.payload;
    },
    [fetchCompanySubscriptionDetails.pending]: (state) => {
      state.companySubscriptionDetailsFetching = true;
    },
  },
});
export const { clearCompanySubscriptionDetailsState } = companySubscriptionDetailsSlice.actions;

export default companySubscriptionDetailsSlice.reducer;
