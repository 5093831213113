import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchOfficialDocumentsList = createAsyncThunk(
  "admin/officialDocument-list",
  async ({ limit, offset, search, status }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.officialDocumentsListURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{search}", search)
          .replace("{status}", status),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const officialDocumentListSlice = createSlice({
  name: "officialDocument-list",
  initialState: {
    officialDocumentList: [],
    officialDocumentListFetching: false,
    officialDocumentListSuccess: false,
    officialDocumentListError: false,
    officialDocumentListErrorMessage: "",
    officialDocumentCount: null,
  },
  reducers: {
    officialDocumentListClearState: (state) => {
      state.officialDocumentListError = false;
      state.officialDocumentListSuccess = false;
      state.officialDocumentListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchOfficialDocumentsList.fulfilled]: (state, { payload }) => {
      state.officialDocumentList = [];
      payload.data.results.forEach((item) =>
        state.officialDocumentList.push(item)
      );
      state.officialDocumentCount = payload.data.count;
      state.officialDocumentListFetching = false;
      state.officialDocumentListSuccess = true;
      return state;
    },
    [fetchOfficialDocumentsList.rejected]: (state, action) => {
      state.officialDocumentListFetching = false;
      state.officialDocumentListError = true;
      state.officialDocumentListErrorMessage = action?.payload;
    },
    [fetchOfficialDocumentsList.pending]: (state) => {
      state.officialDocumentListFetching = true;
    },
  },
});

export const { officialDocumentListClearState } =
  officialDocumentListSlice.actions;

export default officialDocumentListSlice.reducer;
