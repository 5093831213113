import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAttendanceDetailsReport = createAsyncThunk(
  "admin/attendance-detail-report",
  async (
    { limit, offset, startDate,endDate,employee, nationality, department, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url: api.monthlyAttendanceReportURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{startDate}", startDate)
          .replace("{endDate}", endDate)
          .replace("{employee}", employee)
          .replace("{search}", search)
          .replace("{nationality}", nationality)
          .replace("{department}", department),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceDetailReportSlice = createSlice({
  name: "attendance-detail-report",
  initialState: {
    attendanceDetailReport: [],
    attendanceDetailReportFetching: false,
    attendanceDetailReportSuccess: false,
    attendanceDetailReportError: false,
    attendanceDetailReportErrorMessage: "",
    attendanceDetailReportCount: null,
  },
  reducers: {
    attendanceDetailReportClearState: (state) => {
      state.attendanceDetailReportError = false;
      state.attendanceDetailReportSuccess = false;
      state.attendanceDetailReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAttendanceDetailsReport.fulfilled]: (state, { payload }) => {
      state.attendanceDetailReport = [];
      payload.data.results.forEach((item) => state.attendanceDetailReport.push(item));
      state.attendanceDetailReportCount = payload.data.count;
      state.attendanceDetailReportFetching = false;
      state.attendanceDetailReportSuccess = true;
      return state;
    },
    [fetchAttendanceDetailsReport.rejected]: (state, action) => {
      state.attendanceDetailReportFetching = false;
      state.attendanceDetailReportError = true;
      state.attendanceDetailReportErrorMessage = action?.payload;
    },
    [fetchAttendanceDetailsReport.pending]: (state) => {
      state.attendanceDetailReportFetching = true;
    },
  },
});

export const { attendanceDetailReportClearState } = attendanceDetailReportSlice.actions;

export default attendanceDetailReportSlice.reducer;
