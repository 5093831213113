import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const employeeDocumentCreate = createAsyncThunk(
  "admin/create-employeeDocument",
  async ({ payload, employeeID }, { rejectWithValue }) => {
    console.log(employeeID);
    try {
      const response = await api.actionHandler({
        url: api.employeeDocumentsPostURL.replace("{id}", employeeID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeDocumentCreateSlice = createSlice({
  name: "create-employeeDocument",
  initialState: {
    employeeDocumentCreateFetching: false,
    employeeDocumentCreateSuccess: false,
    employeeDocumentCreateError: false,
    employeeDocumentCreateErrorMessage: "",
  },
  reducers: {
    clearCreateEmployeeDocumentState: (state) => {
      state.employeeDocumentCreateError = false;
      state.employeeDocumentCreateSuccess = false;
      state.employeeDocumentCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [employeeDocumentCreate.fulfilled]: (state, { payload }) => {
      state.employeeDocumentCreateFetching = false;
      state.employeeDocumentCreateSuccess = true;
      return state;
    },
    [employeeDocumentCreate.rejected]: (state, action) => {
      state.employeeDocumentCreateFetching = false;
      state.employeeDocumentCreateError = true;
      state.employeeDocumentCreateErrorMessage = action?.payload;
    },
    [employeeDocumentCreate.pending]: (state) => {
      state.employeeDocumentCreateFetching = true;
    },
  },
});

export const { clearCreateEmployeeDocumentState } =
  employeeDocumentCreateSlice.actions;

export default employeeDocumentCreateSlice.reducer;
