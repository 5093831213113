import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAttendanceData = createAsyncThunk(
  "admin/attendance-data",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.attendanceDataURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceDataSlice = createSlice({
  name: "attendance-data",
  initialState: {
    attendanceData: {},
    attendanceDataFetching: false,
    attendanceDataSuccess: false,
    attendanceDataError: false,
    attendanceDataErrorMessage: "",
  },
  reducers: {
    clearAttendanceDataState: (state) => {
      state.attendanceDataError = false;
      state.attendanceDataSuccess = false;
      state.attendanceDataFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAttendanceData.fulfilled]: (state, { payload }) => {
      state.attendanceData = payload.data;
      state.attendanceDataFetching = false;
      state.attendanceDataSuccess = true;
      return state;
    },
    [fetchAttendanceData.rejected]: (state, action) => {
      state.attendanceDataFetching = false;
      state.attendanceDataError = true;
      state.attendanceDataErrorMessage = action?.payload;
    },
    [fetchAttendanceData.pending]: (state) => {
      state.attendanceDataFetching = true;
    },
  },
});
export const { clearAttendanceDataState } = attendanceDataSlice.actions;

export default attendanceDataSlice.reducer;
