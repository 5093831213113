import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteQrCode = createAsyncThunk(
  "admin/delete-qrCode",
  async ({ qrCodeID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.qrCodeDetailsURL.replace("{id}", qrCodeID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const qrCodeDeleteSlice = createSlice({
  name: "delete-qrCode",
  initialState: {
    deleteQrCodeFetching: false,
    deleteQrCodeSuccess: false,
    deleteQrCodeError: false,
    deleteQrCodeErrorMessage: "",
  },
  reducers: {
    deleteQrCodeClearState: (state) => {
      state.deleteQrCodeError = false;
      state.deleteQrCodeSuccess = false;
      state.deleteQrCodeFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteQrCode.fulfilled]: (state, { payload }) => {
      state.deleteQrCodeFetching = false;
      state.deleteQrCodeSuccess = true;
      return state;
    },
    [deleteQrCode.rejected]: (state, action) => {
      state.deleteQrCodeFetching = false;
      state.deleteQrCodeError = true;
      state.deleteQrCodeErrorMessage = action?.payload;
    },
    [deleteQrCode.pending]: (state) => {
      state.deleteQrCodeFetching = true;
    },
  },
});

export const { deleteQrCodeClearState } = qrCodeDeleteSlice.actions;

export default qrCodeDeleteSlice.reducer;
