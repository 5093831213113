import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editHoliday = createAsyncThunk(
  "admin/edit-holiday",
  async ({payload, holidayID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.holidayDetailsURL.replace("{id}", holidayID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const holidayEditSlice = createSlice({
  name: "edit-holiday",
  initialState: {
    editHolidayFetching: false,
    editHolidaySuccess: false,
    editHolidayError: false,
    editHolidayErrorMessage: "",
  },
  reducers: {
    clearEditHolidayState: (state) => {
      state.editHolidayError = false;
      state.editHolidaySuccess = false;
      state.editHolidayFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editHoliday.fulfilled]: (state, { payload }) => {
      state.editHolidayFetching = false;
      state.editHolidaySuccess = true;
      return state;
    },
    [editHoliday.rejected]: (state, action) => {
      state.editHolidayFetching = false;
      state.editHolidayError = true;
      state.editHolidayErrorMessage = action?.payload;
    },
    [editHoliday.pending]: (state) => {
      state.editHolidayFetching = true;
    },
  },
});
export const { clearEditHolidayState } = holidayEditSlice.actions;

export default holidayEditSlice.reducer;
