import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editEmployeeDocument = createAsyncThunk(
  "admin/edit-employeeDocument",
  async ({ payload, employeeID, documentID }, { rejectWithValue }) => {
    try {
      console.log(payload);
      const response = await api.actionHandler({
        url: api.employeeDocumentsDetailsURL
          .replace("{empid}", employeeID)
          .replace("{docid}", documentID),
        method: "PATCH",
        data: payload,
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeDocumentEditSlice = createSlice({
  name: "edit-employeeDocument",
  initialState: {
    editEmployeeDocumentFetching: false,
    editEmployeeDocumentSuccess: false,
    editEmployeeDocumentError: false,
    editEmployeeDocumentErrorMessage: "",
  },
  reducers: {
    clearEditEmployeeDocumentState: (state) => {
      state.editEmployeeDocumentError = false;
      state.editEmployeeDocumentSuccess = false;
      state.editEmployeeDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editEmployeeDocument.fulfilled]: (state, { payload }) => {
      state.editEmployeeDocumentFetching = false;
      state.editEmployeeDocumentSuccess = true;
      return state;
    },
    [editEmployeeDocument.rejected]: (state, action) => {
      state.editEmployeeDocumentFetching = false;
      state.editEmployeeDocumentError = true;
      state.editEmployeeDocumentErrorMessage = action?.payload;
    },
    [editEmployeeDocument.pending]: (state) => {
      state.editEmployeeDocumentFetching = true;
    },
  },
});
export const { clearEditEmployeeDocumentState } =
  employeeDocumentEditSlice.actions;

export default employeeDocumentEditSlice.reducer;
