import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editVehicleDocument = createAsyncThunk(
  "admin/edit-VehicleDocument",
  async ({ payload, vehicleID, documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.vehicleDocumentsEditURL
          .replace(":id", documentID)
          .replace(":vehicleID", vehicleID),
        method: "PATCH",
        data: payload,
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const VehicleDocumentEditSlice = createSlice({
  name: "edit-VehicleDocument",
  initialState: {
    editVehicleDocumentFetching: false,
    editVehicleDocumentSuccess: false,
    editVehicleDocumentError: false,
    editVehicleDocumentErrorMessage: "",
  },
  reducers: {
    clearEditVehicleDocumentState: (state) => {
      state.editVehicleDocumentError = false;
      state.editVehicleDocumentSuccess = false;
      state.editVehicleDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editVehicleDocument.fulfilled]: (state, { payload }) => {
      state.editVehicleDocumentFetching = false;
      state.editVehicleDocumentSuccess = true;
      return state;
    },
    [editVehicleDocument.rejected]: (state, action) => {
      state.editVehicleDocumentFetching = false;
      state.editVehicleDocumentError = true;
      state.editVehicleDocumentErrorMessage = action?.payload;
    },
    [editVehicleDocument.pending]: (state) => {
      state.editVehicleDocumentFetching = true;
    },
  },
});
export const { clearEditVehicleDocumentState } =
  VehicleDocumentEditSlice.actions;

export default VehicleDocumentEditSlice.reducer;
