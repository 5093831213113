import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAbsentTodayList = createAsyncThunk(
  "admin/absent-today-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.absentTodayURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const absentTodaySlice = createSlice({
  name: "absent-today-list",
  initialState: {
    absentTodayList: [],
    absentTodayListFetching: false,
    absentTodayListSuccess: false,
    absentTodayListError: false,
    absentTodayListErrorMessage: "",
    absentTodayCount:null,
  },
  reducers: {
    absentTodayListClearState: (state) => {
      state.absentTodayListError = false;
      state.absentTodayListSuccess = false;
      state.absentTodayListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAbsentTodayList.fulfilled]: (state, { payload }) => {
      state.absentTodayList = [];
      payload.data.results.forEach((item) => state.absentTodayList.push(item));
      state.absentTodayCount = payload.data.count;
      state.absentTodayListFetching = false;
      state.absentTodayListSuccess = true;
      return state;
    },
    [fetchAbsentTodayList.rejected]: (state, action) => {
      state.absentTodayListFetching = false;
      state.absentTodayListError = true;
      state.absentTodayListErrorMessage = action?.payload;
    },
    [fetchAbsentTodayList.pending]: (state) => {
      state.absentTodayListFetching = true;
    },
  },
});

export const { absentTodayListClearState } = absentTodaySlice.actions;

export default absentTodaySlice.reducer;
