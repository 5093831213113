import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchExpiringDocumentsList = createAsyncThunk(
  "admin/expiringDocument-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.expiringDocumentsURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const expiringDocumentListSlice = createSlice({
  name: "expiringDocument-list",
  initialState: {
    expiringDocumentList: [],
    expiringDocumentListFetching: false,
    expiringDocumentListSuccess: false,
    expiringDocumentListError: false,
    expiringDocumentListErrorMessage: "",
    expiringDocumentCount:null,
  },
  reducers: {
    expiringDocumentListClearState: (state) => {
      state.expiringDocumentListError = false;
      state.expiringDocumentListSuccess = false;
      state.expiringDocumentListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchExpiringDocumentsList.fulfilled]: (state, { payload }) => {
      state.expiringDocumentList = [];
      payload.data.results.forEach((item) => state.expiringDocumentList.push(item));
      state.expiringDocumentCount = payload.data.count;
      state.expiringDocumentListFetching = false;
      state.expiringDocumentListSuccess = true;
      return state;
    },
    [fetchExpiringDocumentsList.rejected]: (state, action) => {
      state.expiringDocumentListFetching = false;
      state.expiringDocumentListError = true;
      state.expiringDocumentListErrorMessage = action?.payload;
    },
    [fetchExpiringDocumentsList.pending]: (state) => {
      state.expiringDocumentListFetching = true;
    },
  },
});

export const { expiringDocumentListClearState } = expiringDocumentListSlice.actions;

export default expiringDocumentListSlice.reducer;
