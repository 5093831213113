import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteVehicleDocument = createAsyncThunk(
  "admin/delete-VehicleDocument",
  async ({ vehicleID, documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.VehicleDocumentDeleteURL.replace(":id", documentID).replace(
          ":vehicleID",
          vehicleID
        ),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const VehicleDocumentDeleteSlice = createSlice({
  name: "delete-VehicleDocument",
  initialState: {
    deleteVehicleDocumentFetching: false,
    deleteVehicleDocumentSuccess: false,
    deleteVehicleDocumentError: false,
    deleteVehicleDocumentErrorMessage: "",
  },
  reducers: {
    deleteVehicleDocumentClearState: (state) => {
      state.deleteVehicleDocumentError = false;
      state.deleteVehicleDocumentSuccess = false;
      state.deleteVehicleDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteVehicleDocument.fulfilled]: (state, { payload }) => {
      state.deleteVehicleDocumentFetching = false;
      state.deleteVehicleDocumentSuccess = true;
      return state;
    },
    [deleteVehicleDocument.rejected]: (state, action) => {
      state.deleteVehicleDocumentFetching = false;
      state.deleteVehicleDocumentError = true;
      state.deleteVehicleDocumentErrorMessage = action?.payload;
    },
    [deleteVehicleDocument.pending]: (state) => {
      state.deleteVehicleDocumentFetching = true;
    },
  },
});

export const { deleteVehicleDocumentClearState } =
  VehicleDocumentDeleteSlice.actions;

export default VehicleDocumentDeleteSlice.reducer;
