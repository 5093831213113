import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchEmployeeDocumentDetails = createAsyncThunk(
  "admin/employeeDocument-details",
  async ({ employeeID, documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeDocumentsDetailsURL
          .replace("{empid}", employeeID)
          .replace("{docid}", documentID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeDocumentDetailsSlice = createSlice({
  name: "employeeDocument-details",
  initialState: {
    employeeDocumentDetails: {},
    employeeDocumentDetailsFetching: false,
    employeeDocumentDetailsSuccess: false,
    employeeDocumentDetailsError: false,
    employeeDocumentDetailsErrorMessage: "",
  },
  reducers: {
    clearEmployeeDocumentDetailsState: (state) => {
      state.employeeDocumentDetailsError = false;
      state.employeeDocumentDetailsSuccess = false;
      state.employeeDocumentDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchEmployeeDocumentDetails.fulfilled]: (state, { payload }) => {
      state.employeeDocumentDetails = payload.data;
      state.employeeDocumentDetailsFetching = false;
      state.employeeDocumentDetailsSuccess = true;
      return state;
    },
    [fetchEmployeeDocumentDetails.rejected]: (state, action) => {
      state.employeeDocumentDetailsFetching = false;
      state.employeeDocumentDetailsError = true;
      state.employeeDocumentDetailsErrorMessage = action?.payload;
    },
    [fetchEmployeeDocumentDetails.pending]: (state) => {
      state.employeeDocumentDetailsFetching = true;
    },
  },
});
export const { clearEmployeeDocumentDetailsState } =
  employeeDocumentDetailsSlice.actions;

export default employeeDocumentDetailsSlice.reducer;
