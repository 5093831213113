import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const projectDocumentCreate = createAsyncThunk(
  "admin/create-projectDocument",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectDocumentsPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectDocumentCreateSlice = createSlice({
  name: "create-projectDocument",
  initialState: {
    projectDocumentCreateFetching: false,
    projectDocumentCreateSuccess: false,
    projectDocumentCreateError: false,
    projectDocumentCreateErrorMessage: "",
  },
  reducers: {
    clearCreateprojectDocumentState: (state) => {
      state.projectDocumentCreateError = false;
      state.projectDocumentCreateSuccess = false;
      state.projectDocumentCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [projectDocumentCreate.fulfilled]: (state, { payload }) => {
      state.projectDocumentCreateFetching = false;
      state.projectDocumentCreateSuccess = true;
      return state;
    },
    [projectDocumentCreate.rejected]: (state, action) => {
      state.projectDocumentCreateFetching = false;
      state.projectDocumentCreateError = true;
      state.projectDocumentCreateErrorMessage = action?.payload;
    },
    [projectDocumentCreate.pending]: (state) => {
      state.projectDocumentCreateFetching = true;
    },
  },
});

export const { clearCreateprojectDocumentState } = projectDocumentCreateSlice.actions;

export default projectDocumentCreateSlice.reducer;
