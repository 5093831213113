import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";


export const fetchCategoryList = createAsyncThunk(
  "admin/category-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.categoryListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const categoryListSlice = createSlice({
  name: "category-list",
  initialState: {
    categoryList: [],
    categoryListFetching: false,
    categoryListSuccess: false,
    categoryListError: false,
    categoryListErrorMessage: "",
    categoryCount:null,
  },
  reducers: {
    categoryListClearState: (state) => {
      state.categoryListError = false;
      state.categoryListSuccess = false;
      state.categoryListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCategoryList.fulfilled]: (state, { payload }) => {
      state.categoryList = [];
      payload.data.results.forEach((item) => state.categoryList.push(item));
      state.categoryCount = payload.data.count;
      state.categoryListFetching = false;
      state.categoryListSuccess = true;
      return state;
    },
    [fetchCategoryList.rejected]: (state, action) => {
      state.categoryListFetching = false;
      state.categoryListError = true;
      state.categoryListErrorMessage = action?.payload;
    },
    [fetchCategoryList.pending]: (state) => {
      state.categoryListFetching = true;
    },
  },
});

export const { categoryListClearState } = categoryListSlice.actions;

export default categoryListSlice.reducer;
