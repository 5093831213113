import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTimeZoneList = createAsyncThunk(
  "admin/timeZone-list",
  async ({countryID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timezoneListURL.replace("{id}", countryID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timeZoneListSlice = createSlice({
  name: "timeZone-list",
  initialState: {
    timeZoneList: [],
    timeZoneListFetching: false,
    timeZoneListSuccess: false,
    timeZoneListError: false,
    timeZoneListErrorMessage: "",
    timeZoneCount:null,
  },
  reducers: {
    timeZoneListClearState: (state) => {
      state.timeZoneListError = false;
      state.timeZoneListSuccess = false;
      state.timeZoneListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTimeZoneList.fulfilled]: (state, { payload }) => {
      state.timeZoneList = [];
      payload.result.forEach((item) => state.timeZoneList.push(item));
      state.timeZoneListFetching = false;
      state.timeZoneListSuccess = true;
      return state;
    },
    [fetchTimeZoneList.rejected]: (state, action) => {
      state.timeZoneListFetching = false;
      state.timeZoneListError = true;
      state.timeZoneListErrorMessage = action?.payload;
    },
    [fetchTimeZoneList.pending]: (state) => {
      state.timeZoneListFetching = true;
    },
  },
});

export const { timeZoneListClearState } = timeZoneListSlice.actions;

export default timeZoneListSlice.reducer;
