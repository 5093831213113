import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchvehicleDocumentsList = createAsyncThunk(
  "admin/vehicleDocument-list",
  async ({ limit, offset, search, vehicleID, status }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.vehicleDocumentListURL
          .replace("{statusID}", status)
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{search}", search)
          .replace(":id", vehicleID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const vehicleDocumentListSlice = createSlice({
  name: "vehicleDocument-list",
  initialState: {
    vehicleDocumentList: [],
    vehicleDocumentListFetching: false,
    vehicleDocumentListSuccess: false,
    vehicleDocumentListError: false,
    vehicleDocumentListErrorMessage: "",
    vehicleDocumentCount: null,
  },
  reducers: {
    vehicleDocumentListClearState: (state) => {
      state.vehicleDocumentListError = false;
      state.vehicleDocumentListSuccess = false;
      state.vehicleDocumentListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchvehicleDocumentsList.fulfilled]: (state, { payload }) => {
      state.vehicleDocumentList = [];
      payload.data.results.forEach((item) =>
        state.vehicleDocumentList.push(item)
      );
      state.vehicleDocumentCount = payload.data.count;
      state.vehicleDocumentListFetching = false;
      state.vehicleDocumentListSuccess = true;
      return state;
    },
    [fetchvehicleDocumentsList.rejected]: (state, action) => {
      state.vehicleDocumentListFetching = false;
      state.vehicleDocumentListError = true;
      state.vehicleDocumentListErrorMessage = action?.payload;
    },
    [fetchvehicleDocumentsList.pending]: (state) => {
      state.vehicleDocumentListFetching = true;
    },
  },
});

export const { vehicleDocumentListClearState } =
  vehicleDocumentListSlice.actions;

export default vehicleDocumentListSlice.reducer;
