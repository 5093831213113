import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchPendingLeavesList = createAsyncThunk(
  "admin/pendingLeave-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.pendingLeavesListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const pendingLeaveListSlice = createSlice({
  name: "pendingLeave-list",
  initialState: {
    pendingLeaveList: [],
    pendingLeaveListFetching: false,
    pendingLeaveListSuccess: false,
    pendingLeaveListError: false,
    pendingLeaveListErrorMessage: "",
    pendingLeaveCount:null,
  },
  reducers: {
    pendingLeaveListClearState: (state) => {
      state.pendingLeaveListError = false;
      state.pendingLeaveListSuccess = false;
      state.pendingLeaveListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchPendingLeavesList.fulfilled]: (state, { payload }) => {
      state.pendingLeaveList = [];
      payload.data.results.forEach((item) => state.pendingLeaveList.push(item));
      state.pendingLeaveCount = payload.data.count;
      state.pendingLeaveListFetching = false;
      state.pendingLeaveListSuccess = true;
      return state;
    },
    [fetchPendingLeavesList.rejected]: (state, action) => {
      state.pendingLeaveListFetching = false;
      state.pendingLeaveListError = true;
      state.pendingLeaveListErrorMessage = action?.payload;
    },
    [fetchPendingLeavesList.pending]: (state) => {
      state.pendingLeaveListFetching = true;
    },
  },
});

export const { pendingLeaveListClearState } = pendingLeaveListSlice.actions;

export default pendingLeaveListSlice.reducer;
