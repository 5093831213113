import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchprojectDocumentsList = createAsyncThunk(
  "admin/projectDocument-list",
  async ({limit ,offset ,search,status}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectDocumentListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search).replace("{statusID}",status),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectDocumentListSlice = createSlice({
  name: "projectDocument-list",
  initialState: {
    projectDocumentList: [],
    projectDocumentListFetching: false,
    projectDocumentListSuccess: false,
    projectDocumentListError: false,
    projectDocumentListErrorMessage: "",
    projectDocumentCount:null,
  },
  reducers: {
    projectDocumentListClearState: (state) => {
      state.projectDocumentListError = false;
      state.projectDocumentListSuccess = false;
      state.projectDocumentListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchprojectDocumentsList.fulfilled]: (state, { payload }) => {
      state.projectDocumentList = [];
      payload.data.results.forEach((item) => state.projectDocumentList.push(item));
      state.projectDocumentCount = payload.data.count;
      state.projectDocumentListFetching = false;
      state.projectDocumentListSuccess = true;
      return state;
    },
    [fetchprojectDocumentsList.rejected]: (state, action) => {
      state.projectDocumentListFetching = false;
      state.projectDocumentListError = true;
      state.projectDocumentListErrorMessage = action?.payload;
    },
    [fetchprojectDocumentsList.pending]: (state) => {
      state.projectDocumentListFetching = true;
    },
  },
});

export const { projectDocumentListClearState } = projectDocumentListSlice.actions;

export default projectDocumentListSlice.reducer;
