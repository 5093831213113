import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchOwnerCompanyList = createAsyncThunk(
  "admin/ownerCompany-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.ownerCompanyListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const ownerCompanyListSlice = createSlice({
  name: "ownerCompany-list",
  initialState: {
    ownerCompanyList: [],
    ownerCompanyListFetching: false,
    ownerCompanyListSuccess: false,
    ownerCompanyListError: false,
    ownerCompanyListErrorMessage: "",
    ownerCompanyCount:null,
  },
  reducers: {
    ownerCompanyListClearState: (state) => {
      state.ownerCompanyListError = false;
      state.ownerCompanyListSuccess = false;
      state.ownerCompanyListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchOwnerCompanyList.fulfilled]: (state, { payload }) => {
      state.ownerCompanyList = [];
      payload.data.results.forEach((item) => state.ownerCompanyList.push(item));
      state.ownerCompanyCount = payload.data.count;
      state.ownerCompanyListFetching = false;
      state.ownerCompanyListSuccess = true;
      return state;
    },
    [fetchOwnerCompanyList.rejected]: (state, action) => {
      state.ownerCompanyListFetching = false;
      state.ownerCompanyListError = true;
      state.ownerCompanyListErrorMessage = action?.payload;
    },
    [fetchOwnerCompanyList.pending]: (state) => {
      state.ownerCompanyListFetching = true;
    },
  },
});

export const { ownerCompanyListClearState } = ownerCompanyListSlice.actions;

export default ownerCompanyListSlice.reducer;
