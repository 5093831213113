import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteHoliday = createAsyncThunk(
  "admin/delete-holiday",
  async ({ holidayID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.holidayDetailsURL.replace("{id}", holidayID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const holidayDeleteSlice = createSlice({
  name: "delete-holiday",
  initialState: {
    deleteHolidayFetching: false,
    deleteHolidaySuccess: false,
    deleteHolidayError: false,
    deleteHolidayErrorMessage: "",
  },
  reducers: {
    deleteHolidayClearState: (state) => {
      state.deleteHolidayError = false;
      state.deleteHolidaySuccess = false;
      state.deleteHolidayFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteHoliday.fulfilled]: (state, { payload }) => {
      state.deleteHolidayFetching = false;
      state.deleteHolidaySuccess = true;
      return state;
    },
    [deleteHoliday.rejected]: (state, action) => {
      state.deleteHolidayFetching = false;
      state.deleteHolidayError = true;
      state.deleteHolidayErrorMessage = action?.payload;
    },
    [deleteHoliday.pending]: (state) => {
      state.deleteHolidayFetching = true;
    },
  },
});

export const { deleteHolidayClearState } = holidayDeleteSlice.actions;

export default holidayDeleteSlice.reducer;
