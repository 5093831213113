import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchSubscriptionPlanDetails = createAsyncThunk(
  "admin/subscriptionPlan-details",
  async ({ planID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subscriptionPlanDetailsURL.replace("{id}", planID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subscriptionPlanDetailsSlice = createSlice({
  name: "subscriptionPlan-details",
  initialState: {
    subscriptionPlanDetails: {},
    subscriptionPlanDetailsFetching: false,
    subscriptionPlanDetailsSuccess: false,
    subscriptionPlanDetailsError: false,
    subscriptionPlanDetailsErrorMessage: "",
  },
  reducers: {
    clearSubscriptionPlanDetailsState: (state) => {
      state.subscriptionPlanDetailsError = false;
      state.subscriptionPlanDetailsSuccess = false;
      state.subscriptionPlanDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubscriptionPlanDetails.fulfilled]: (state, { payload }) => {
      state.subscriptionPlanDetails = payload.data;
      state.subscriptionPlanDetailsFetching = false;
      state.subscriptionPlanDetailsSuccess = true;
      return state;
    },
    [fetchSubscriptionPlanDetails.rejected]: (state, action) => {
      state.subscriptionPlanDetailsFetching = false;
      state.subscriptionPlanDetailsError = true;
      state.subscriptionPlanDetailsErrorMessage = action?.payload;
    },
    [fetchSubscriptionPlanDetails.pending]: (state) => {
      state.subscriptionPlanDetailsFetching = true;
    },
  },
});
export const { clearSubscriptionPlanDetailsState } = subscriptionPlanDetailsSlice.actions;

export default subscriptionPlanDetailsSlice.reducer;
