import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword ,clearResetPasswordState} from "../../store/login/resetPasswordSlice";
import { notifyError, notifySuccess } from "../../components/custom/Toast";

const ResetPassword = ({ toggle, setToggle  }) => {
  const dispatch = useDispatch();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const history = useHistory();

  const handleSave = async (e) => {
    e.preventDefault();
    let payload = {
      password,
    };

    let error = undefined;
    if (password === "") {
      toast.error("Please enter password");
    } else if (password !== confirm_password) {
      toast.error("Password does not match");
    }

    if (error) {
      notifyError(error);
      return;
    } else {
      dispatch(resetPassword({ payload }));
    }
  };

  useEffect(() => {
    if (toggle) {
      setPassword("");
      setConfirmPassword("");
    }
  }, [toggle]);

  const { resetPasswordSuccess, resetPasswordError, resetPasswordErrorMessage } =
    useSelector((state) => state.resetPasswordSlice);

  useEffect(() => {
    if (resetPasswordSuccess) {
      setToggle(false);
      dispatch(clearResetPasswordState());
      notifySuccess("Password Reset successfully");
      localStorage.removeItem("admin-token");
      localStorage.removeItem("username");
      localStorage.removeItem("user-type");
      localStorage.removeItem("time-zone");
      localStorage.removeItem("permissions");
      history.push("/auth/sign-in");
    } else if (resetPasswordError) {
      notifyError(resetPasswordErrorMessage);
      dispatch(clearResetPasswordState());
    }
  }, [resetPasswordSuccess, resetPasswordError]);

  return (
    <div className="modal" id="modal">
      <Modal size="md" top show={toggle} onHide={setToggle}>
        <Modal.Body className="">
          <Row
            className="justify-content-center align-items-center"
            style={{ height: "20px" }}
          >
            <Col xl={12}>
              <h3 className="ms-4 text-primary">Reset Password </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Body className="px-4 mx-3">
          <>
            <Row>
              <Col lg="12" className="col-lg-12">
                <Form.Group className="floating-label">
                  <Form.Label htmlFor="password" className="form-label">
                    Password
                  </Form.Label>
                  <div className="input-group col-md-4 rounded-2 ">
                    <input
                      className="form-control py-2 sign-inp "
                      id="password"
                      name="password"
                      aria-describedby="password"
                      type={viewPassword ? "text" : "password"}
                      placeholder="Password"
                      // style={{ border: "1px solid #CCDBE1",background: "#f5f5f5" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="input-group-append py-2 sign-inp"
                      onClick={() => setViewPassword(!viewPassword)}
                      style={{ border: "1px solid #CCDBE0" }}
                    >
                      <div className="btn btn-sm ">
                        {viewPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        )}
                      </div>
                    </span>
                  </div>
                  <Form.Label
                    htmlFor="confirm_password"
                    className="form-label mt-3"
                  >
                    Confirm Password
                  </Form.Label>
                  <div className="input-group col-md-4 rounded-2 ">
                    <input
                      className="form-control py-2 sign-inp "
                      id="confirm_password"
                      name="confirm_password"
                      aria-describedby="confirm_password"
                      type={viewConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      // style={{ border: "1px solid #CCDBE1",background: "#f5f5f5" }}
                      value={confirm_password}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span
                      className="input-group-append py-2 sign-inp"
                      onClick={() =>
                        setViewConfirmPassword(!viewConfirmPassword)
                      }
                      style={{ border: "1px solid #CCDBE0" }}
                    >
                      <div className="btn btn-sm ">
                        {viewConfirmPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </svg>
                        )}
                      </div>
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    borderRadius: "8px",
                  }}
                  className="mb-3 mt-5 btn-info "
                  onClick={() => setToggle(false)}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    borderRadius: "8px",
                  }}
                  className="mb-3 ms-3 ps-5 pe-5 mt-5 "
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </Row>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ResetPassword;
