import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchOwnerDetails = createAsyncThunk(
  "admin/owner-details",
  async ({ ownerID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.ownerDetailsURL.replace("{id}", ownerID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const ownerDetailsSlice = createSlice({
  name: "owner-details",
  initialState: {
    ownerDetails: {},
    ownerDetailsFetching: false,
    ownerDetailsSuccess: false,
    ownerDetailsError: false,
    ownerDetailsErrorMessage: "",
  },
  reducers: {
    clearOwnerDetailsState: (state) => {
      state.ownerDetailsError = false;
      state.ownerDetailsSuccess = false;
      state.ownerDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchOwnerDetails.fulfilled]: (state, { payload }) => {
      state.ownerDetails = payload.data;
      state.ownerDetailsFetching = false;
      state.ownerDetailsSuccess = true;
      return state;
    },
    [fetchOwnerDetails.rejected]: (state, action) => {
      state.ownerDetailsFetching = false;
      state.ownerDetailsError = true;
      state.ownerDetailsErrorMessage = action?.payload;
    },
    [fetchOwnerDetails.pending]: (state) => {
      state.ownerDetailsFetching = true;
    },
  },
});
export const { clearOwnerDetailsState } = ownerDetailsSlice.actions;

export default ownerDetailsSlice.reducer;
