import { toast } from 'react-toastify'

const notifySuccess = (message) =>
  toast.success(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    type: 'success',
    theme: 'light',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

const notifyError = (message) =>
  toast.error(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    type: 'error',
    theme: 'light',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

export { notifySuccess, notifyError }
