import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const editFlexibleTimeSchedule = createAsyncThunk(
  "admin/edit-flexibleTimeSchedule",
  async ({payload, flexibleScheduleID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleTimeScheduleDetailsURL.replace("{id}", flexibleScheduleID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleTimeScheduleEditSlice = createSlice({
  name: "edit-flexibleTimeSchedule",
  initialState: {
    editFlexibleTimeScheduleFetching: false,
    editFlexibleTimeScheduleSuccess: false,
    editFlexibleTimeScheduleError: false,
    editFlexibleTimeScheduleErrorMessage: "",
  },
  reducers: {
    clearEditFlexibleTimeScheduleState: (state) => {
      state.editFlexibleTimeScheduleError = false;
      state.editFlexibleTimeScheduleSuccess = false;
      state.editFlexibleTimeScheduleFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editFlexibleTimeSchedule.fulfilled]: (state, { payload }) => {
      state.editFlexibleTimeScheduleFetching = false;
      state.editFlexibleTimeScheduleSuccess = true;
      return state;
    },
    [editFlexibleTimeSchedule.rejected]: (state, action) => {
      state.editFlexibleTimeScheduleFetching = false;
      state.editFlexibleTimeScheduleError = true;
      state.editFlexibleTimeScheduleErrorMessage = action?.payload;
    },
    [editFlexibleTimeSchedule.pending]: (state) => {
      state.editFlexibleTimeScheduleFetching = true;
    },
  },
});
export const { clearEditFlexibleTimeScheduleState } = flexibleTimeScheduleEditSlice.actions;

export default flexibleTimeScheduleEditSlice.reducer;
