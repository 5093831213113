import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

//Dashboard
const Index = lazy(() => import("../views/dashboard/index"));
const DashboardReport = lazy(() =>
  import("../views/dashboard/DashboardReport")
);
// Empolyee
const Employee = lazy(() => import("../views/employee/Empolyee"));
const EmployeeDetails = lazy(() => import("../views/employee/EmpolyeeDetails"));

// AttandanceReports
const AttandanceReports = lazy(() =>
  import("../views/attandance-reports/AttandanceReports")
);

// AttandanceReportsDetails
const AttandanceReportsDetails = lazy(() =>
  import("../views/attandance-reports/AttandanceReportsDetails")
);

// DocumentList
const DocumentList = lazy(() => import("../views/Document list/DocumentList"));

// OfficialDocuments
const OfficialDocuments = lazy(() =>
  import("../views/Document list/CompanyDocuments/OfficialDocuments")
);

// OfficialDocumentDetails
const OfficialDocumentDetails = lazy(() =>
  import("../views/Document list/CompanyDocuments/OfficialDocumentDetails")
);

// ProjectDocuments
const ProjectDocuments = lazy(() =>
  import("../views/Document list/ProjectDocuments/ProjectDocuments")
);

// ProjectDocuments
const ProjectDocumentsDetails = lazy(() =>
  import("../views/Document list/ProjectDocuments/ProjectDocumentsDetails")
);

//Employee Documents
const EmployeesDocumentsEmployeeList = lazy(() =>
  import(
    "../views/Document list/Employees Documents/EmployeesDocumentsEmployeeList"
  )
);

// Employee Documents
const EmployeeDocuments = lazy(() =>
  import("../views/Document list/Employees Documents/EmployeeDocuments")
);

// Employee Documents
const EmployeeDocumentDetails = lazy(() =>
  import("../views/Document list/Employees Documents/EmployeeDocumentDetails")
);

//VehicleDocuments
const VehicleDocuments = lazy(() =>
  import("../views/Document list/VehicleDocuments/VehicleDocuments")
);

//VehicleDocuments
const VehicleEmployeeList = lazy(() =>
  import("../views/Document list/VehicleDocuments/VehicleDocumentsVehicleList")
);

//VehicleDocumentsDetails
const vehicleDocumentsDetails = lazy(() =>
  import("../views/Document list/VehicleDocuments/vehicleDocumentsDetails")
);

// ProjectDocuments
const SalaryManageList = lazy(() =>
  import("../views/SalaryManage/SalaryManageList")
);

// ProjectDocuments
const SalaryDetails = lazy(() => import("../views/SalaryManage/SalaryDetails"));

// RewardsList
const RewardsList = lazy(() => import("../views/RewardsList/RewardsList"));
// RewardsDetails
const RewardsDetails = lazy(() =>
  import("../views/RewardsList/RewardsDetails")
);

// MeetingMinits
const MeetingMinits = lazy(() =>
  import("../views/MeetingMinits/MeetingMinits")
);

// SceduledMeeting
const ScheduledMeetings = lazy(() =>
  import("../views/MeetingMinits/SceduleANewMeeting/SceduledMeeting")
);

// UploadMinitsList
const UploadMinitsList = lazy(() =>
  import("../views/MeetingMinits/UploadMinits/UploadMinitsList")
);

// EventsAnnouncement
const EventsAnnouncement = lazy(() =>
  import("../views/Events&Announcement/EventsAnnouncement")
);

// EventsAnnouncement
const EventsList = lazy(() =>
  import("../views/Events&Announcement/Events/EventsList")
);

// EventDetails
const EventDetails = lazy(() =>
  import("../views/Events&Announcement/Events/EventDetails")
);

// AnnouncementsList
const AnnouncementsList = lazy(() =>
  import("../views/Events&Announcement/Announcements/AnnouncementsList")
);

// AnnouncementsDetails
const AnnouncementsDetails = lazy(() =>
  import("../views/Events&Announcement/Announcements/AnnouncementsDetails")
);

// LeaveApplyList
const LeaveApplyList = lazy(() =>
  import("../views/LeaveApplyList/LeaveApplyList")
);

// LeaveApplyDetails
const LeaveApplyDetails = lazy(() =>
  import("../views/LeaveApplyList/LeaveApplyDetails")
);

// LeaveApplyDetails
const ComplaintRegistry = lazy(() =>
  import("../views/ComplaintRegistry/ComplaintRegistry")
);

// ComplaintRegistryDetails
const ComplaintRegistryDetails = lazy(() =>
  import("../views/ComplaintRegistry/ComplaintRegistryDetails")
);

// ComplaintRegistryDetails
const UserList = lazy(() => import("../views/UserList/UserList"));

//Settings
const Settings = lazy(() => import("../views/Settings/index"));
//qr code
const QrCode = lazy(() => import("../views/QRCodes/QrCode"));

//Department
const Department = lazy(() =>
  import("../views/Settings/Department/Department")
);

const SubscriptionExpired = lazy(() =>
  import("../views/dashboard/NoSubscriptionPage")
);

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={200}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Index} />
            <Route
              path="/dashboard/:reportType"
              exact
              component={DashboardReport}
            />
            <Route path="/employee" exact component={Employee} />
            <Route
              path="/employee/:employeeID/employee-details"
              component={EmployeeDetails}
            />
            <Route
              path="/attendance/:active_tab?"
              exact
              component={AttandanceReports}
            />
            <Route
              path="/attendance-report/:employeeID/details"
              exact
              component={AttandanceReportsDetails}
            />
            <Route path="/document-list" exact component={DocumentList} />

            <Route
              path="/official-documents/:statusID"
              exact
              component={OfficialDocuments}
            />
            <Route
              path="/official-document/:documentID/details"
              exact
              component={OfficialDocumentDetails}
            />
            <Route
              path="/project-documents/:statusID"
              exact
              component={ProjectDocuments}
            />
            <Route
              path="/project-document/:documentID/details"
              exact
              component={ProjectDocumentsDetails}
            />
            <Route
              path="/vehicle/:vehicleID/vehicle-document/:documentID/details"
              exact
              component={vehicleDocumentsDetails}
            />
            <Route
              path="/employee-documents-list"
              exact
              component={EmployeesDocumentsEmployeeList}
            />
            <Route
              path="/employee/:employeeID/employee-documents"
              exact
              component={EmployeeDocuments}
            />
            <Route
              path="/employee/:employeeID/employee-document/:documentID/details"
              exact
              component={EmployeeDocumentDetails}
            />
            <Route
              path="/vehicle-documents"
              exact
              component={VehicleEmployeeList}
            />
            <Route
              path="/vehicle/:vehicleID/vehicle-documents"
              exact
              component={VehicleDocuments}
            />
            <Route
              path="/salarymanagelist"
              exact
              component={SalaryManageList}
            />
            <Route path="/salarydetails/{id}" exact component={SalaryDetails} />
            <Route path="/rewardslist" exact component={RewardsList} />
            <Route
              path="/rewardsdetails/{id}"
              exact
              component={RewardsDetails}
            />
            <Route path="/meetingminits" exact component={MeetingMinits} />
            <Route
              path="/sceduled-meeting"
              exact
              component={ScheduledMeetings}
            />
            <Route
              path="/upload-minits-list"
              exact
              component={UploadMinitsList}
            />
            <Route
              path="/events-announcement"
              exact
              component={EventsAnnouncement}
            />
            <Route path="/events-lists" exact component={EventsList} />
            <Route path="/events-details" exact component={EventDetails} />
            <Route
              path="/announcements-list"
              exact
              component={AnnouncementsList}
            />
            <Route
              path="/announcements-details/{id}"
              exact
              component={AnnouncementsDetails}
            />
            <Route path="/leave-apply-list" exact component={LeaveApplyList} />
            <Route
              path="/leave-details/{id}"
              exact
              component={LeaveApplyDetails}
            />
            <Route
              path="/complaint-registry"
              exact
              component={ComplaintRegistry}
            />
            <Route
              path="/complaint-registry/{id}"
              exact
              component={ComplaintRegistryDetails}
            />
            <Route path="/department" exact component={Department} />
            <Route
              path="/subscription-expired"
              exact
              component={SubscriptionExpired}
            />

            <Route path="/user-list" exact component={UserList} />
            <Route path="/settings/:active_tab?" component={Settings} />
            <Route path="/qr-code" component={QrCode} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
