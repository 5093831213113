import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

//Dashboard
const AdminDashboard = lazy(() =>import("../views/admin/admin-dashboard/index"));

//Owners List
const OwnersList = lazy(() => import("../views/admin/Owners/OwnersList"));

const Companies = lazy(() => import("../views/admin/Owners/CompanyList/Index"));

const CompanyDashboard = lazy(() => import("../views/admin/Owners/CompanyList/Dashboard"));


// Branch List
const BranchList = lazy(() => import("../views/admin/branchList/BranchList"));

// User List
const UserList = lazy(() => import("../views/admin/userList/UserList"));

//Subscription
const Subscription = lazy(() => import("../views/admin/subscription/Subscription"));

// Cash Received
const CashReceived= lazy(() =>import("../views/admin/subscription/CashRecieved"));

// LIcense Expired
const LIcenseExpired = lazy(() =>import("../views/admin/subscription/LIcenseExpired"));

// Branch Details
const BranchDetails = lazy(() =>import("../views/admin/branchList/BranchDetails"));

// User Details
const UserDetails = lazy(() =>import("../views/admin/userList/UserDetails"));

//Settings

const AdminSettings = lazy(() => import("../views/admin/Settings/index"));

const AdminRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={200}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/admin-dashboard" exact component={AdminDashboard} />
            <Route path="/owners" exact component={OwnersList} />
            <Route path="/owners/:ownerID/companies" exact component={Companies} />
            <Route path="/owners/:ownerID/companies/:companyID/:active_tab?" exact component={CompanyDashboard} />
            <Route path="/branch-list" exact component={BranchList} />
            <Route path="/admin-user-list" exact component={UserList} />
            <Route path="/subscription" exact component={Subscription} />
            <Route path="/cash-recieved/{id}" exact component={CashReceived} />
            <Route path="/license-expired/{id}" exact component={LIcenseExpired}/>
            <Route path="/branch-details/{id}" exact component={BranchDetails}/>
            <Route path="/user-details/{id}" exact component={UserDetails}/>
            <Route path="/admin-settings/:active_tab?" exact component={AdminSettings} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

AdminRouter.displayName = "AdminRouter";
export default AdminRouter;
