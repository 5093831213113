import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteOwner = createAsyncThunk(
  "admin/delete-owner",
  async ({ ownerID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.ownerDetailsURL.replace("{id}", ownerID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const ownerDeleteSlice = createSlice({
  name: "delete-owner",
  initialState: {
    deleteOwnerFetching: false,
    deleteOwnerSuccess: false,
    deleteOwnerError: false,
    deleteOwnerErrorMessage: "",
  },
  reducers: {
    deleteOwnerClearState: (state) => {
      state.deleteOwnerError = false;
      state.deleteOwnerSuccess = false;
      state.deleteOwnerFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteOwner.fulfilled]: (state, { payload }) => {
      state.deleteOwnerFetching = false;
      state.deleteOwnerSuccess = true;
      return state;
    },
    [deleteOwner.rejected]: (state, action) => {
      state.deleteOwnerFetching = false;
      state.deleteOwnerError = true;
      state.deleteOwnerErrorMessage = action?.payload;
    },
    [deleteOwner.pending]: (state) => {
      state.deleteOwnerFetching = true;
    },
  },
});

export const { deleteOwnerClearState } = ownerDeleteSlice.actions;

export default ownerDeleteSlice.reducer;
