import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchDashboardData = createAsyncThunk(
  "admin/dashboard-data",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adminDashboardDataURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const dashboardDataSlice = createSlice({
  name: "dashboard-data",
  initialState: {
    dashboardData: {},
    dashboardDataFetching: false,
    dashboardDataSuccess: false,
    dashboardDataError: false,
    dashboardDataErrorMessage: "",
    timeZoneCount:null,
  },
  reducers: {
    dashboardDataClearState: (state) => {
      state.dashboardDataError = false;
      state.dashboardDataSuccess = false;
      state.dashboardDataFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDashboardData.fulfilled]: (state, { payload }) => {
      state.dashboardData = {}
      state.dashboardData=payload.result
      state.dashboardDataFetching = false;
      state.dashboardDataSuccess = true;
      return state;
    },
    [fetchDashboardData.rejected]: (state, action) => {
      state.dashboardDataFetching = false;
      state.dashboardDataError = true;
      state.dashboardDataErrorMessage = action?.payload;
    },
    [fetchDashboardData.pending]: (state) => {
      state.dashboardDataFetching = true;
    },
  },
});

export const { dashboardDataClearState } = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;
