import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editDepartment = createAsyncThunk(
  "admin/edit-department",
  async ({payload, departmentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.departmentDetailsURL.replace("{id}", departmentID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const departmentEditSlice = createSlice({
  name: "edit-department",
  initialState: {
    editDepartmentFetching: false,
    editDepartmentSuccess: false,
    editDepartmentError: false,
    editDepartmentErrorMessage: "",
  },
  reducers: {
    clearEditDepartmentState: (state) => {
      state.editDepartmentError = false;
      state.editDepartmentSuccess = false;
      state.editDepartmentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editDepartment.fulfilled]: (state, { payload }) => {
      state.editDepartmentFetching = false;
      state.editDepartmentSuccess = true;
      return state;
    },
    [editDepartment.rejected]: (state, action) => {
      state.editDepartmentFetching = false;
      state.editDepartmentError = true;
      state.editDepartmentErrorMessage = action?.payload;
    },
    [editDepartment.pending]: (state) => {
      state.editDepartmentFetching = true;
    },
  },
});
export const { clearEditDepartmentState } = departmentEditSlice.actions;

export default departmentEditSlice.reducer;
