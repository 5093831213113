import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchLateReport = createAsyncThunk(
  "admin/late-report",
  async (
    { limit, offset, startDate, endDate,},
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url: api.lateReportURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{startDate}", startDate)
          .replace("{endDate}", endDate),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const lateReportSlice = createSlice({
  name: "late-report",
  initialState: {
    lateReport: [],
    lateReportFetching: false,
    lateReportSuccess: false,
    lateReportError: false,
    lateReportErrorMessage: "",
    lateCount: null,
  },
  reducers: {
    lateReportClearState: (state) => {
      state.lateReportError = false;
      state.lateReportSuccess = false;
      state.lateReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchLateReport.fulfilled]: (state, { payload }) => {
      state.lateReport = [];
      payload.data.results.forEach((item) => state.lateReport.push(item));
      state.lateCount = payload.data.count;
      state.lateReportFetching = false;
      state.lateReportSuccess = true;
      return state;
    },
    [fetchLateReport.rejected]: (state, action) => {
      state.lateReportFetching = false;
      state.lateReportError = true;
      state.lateReportErrorMessage = action?.payload;
    },
    [fetchLateReport.pending]: (state) => {
      state.lateReportFetching = true;
    },
  },
});

export const { lateReportClearState } = lateReportSlice.actions;

export default lateReportSlice.reducer;
