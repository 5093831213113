import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const deleteNormalTimeSchedule = createAsyncThunk(
  "admin/delete-normalTimeSchedule",
  async ({ normalScheduleID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.normalTimeScheduleDetailsURL.replace("{id}", normalScheduleID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const normalTimeScheduleDeleteSlice = createSlice({
  name: "delete-normalTimeSchedule",
  initialState: {
    deleteNormalTimeScheduleFetching: false,
    deleteNormalTimeScheduleSuccess: false,
    deleteNormalTimeScheduleError: false,
    deleteNormalTimeScheduleErrorMessage: "",
  },
  reducers: {
    deleteNormalTimeScheduleClearState: (state) => {
      state.deleteNormalTimeScheduleError = false;
      state.deleteNormalTimeScheduleSuccess = false;
      state.deleteNormalTimeScheduleFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteNormalTimeSchedule.fulfilled]: (state, { payload }) => {
      state.deleteNormalTimeScheduleFetching = false;
      state.deleteNormalTimeScheduleSuccess = true;
      return state;
    },
    [deleteNormalTimeSchedule.rejected]: (state, action) => {
      state.deleteNormalTimeScheduleFetching = false;
      state.deleteNormalTimeScheduleError = true;
      state.deleteNormalTimeScheduleErrorMessage = action?.payload;
    },
    [deleteNormalTimeSchedule.pending]: (state) => {
      state.deleteNormalTimeScheduleFetching = true;
    },
  },
});

export const { deleteNormalTimeScheduleClearState } = normalTimeScheduleDeleteSlice.actions;

export default normalTimeScheduleDeleteSlice.reducer;
