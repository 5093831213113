import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const officialDocumentCreate = createAsyncThunk(
  "admin/create-officialDocument",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.officialDocumentsPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const officialDocumentCreateSlice = createSlice({
  name: "create-officialDocument",
  initialState: {
    officialDocumentCreateFetching: false,
    officialDocumentCreateSuccess: false,
    officialDocumentCreateError: false,
    officialDocumentCreateErrorMessage: "",
  },
  reducers: {
    clearCreateOfficialDocumentState: (state) => {
      state.officialDocumentCreateError = false;
      state.officialDocumentCreateSuccess = false;
      state.officialDocumentCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [officialDocumentCreate.fulfilled]: (state, { payload }) => {
      state.officialDocumentCreateFetching = false;
      state.officialDocumentCreateSuccess = true;
      return state;
    },
    [officialDocumentCreate.rejected]: (state, action) => {
      state.officialDocumentCreateFetching = false;
      state.officialDocumentCreateError = true;
      state.officialDocumentCreateErrorMessage = action?.payload;
    },
    [officialDocumentCreate.pending]: (state) => {
      state.officialDocumentCreateFetching = true;
    },
  },
});

export const { clearCreateOfficialDocumentState } = officialDocumentCreateSlice.actions;

export default officialDocumentCreateSlice.reducer;
