import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStaffsList = createAsyncThunk(
  "admin/staff-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.staffsListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const staffListSlice = createSlice({
  name: "staff-list",
  initialState: {
    staffList: [],
    staffListFetching: false,
    staffListSuccess: false,
    staffListError: false,
    staffListErrorMessage: "",
    staffCount:null,
  },
  reducers: {
    staffListClearState: (state) => {
      state.staffListError = false;
      state.staffListSuccess = false;
      state.staffListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStaffsList.fulfilled]: (state, { payload }) => {
      state.staffList = [];
      payload.data.results.forEach((item) => state.staffList.push(item));
      state.staffCount = payload.data.count;
      state.staffListFetching = false;
      state.staffListSuccess = true;
      return state;
    },
    [fetchStaffsList.rejected]: (state, action) => {
      state.staffListFetching = false;
      state.staffListError = true;
      state.staffListErrorMessage = action?.payload;
    },
    [fetchStaffsList.pending]: (state) => {
      state.staffListFetching = true;
    },
  },
});

export const { staffListClearState } = staffListSlice.actions;

export default staffListSlice.reducer;
