import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const forgotPassword = createAsyncThunk(
  "admin/forgot-password",
  async ({ payload ,userID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.forgotPasswordURL.replace("{id}", userID),
        method: "PUT",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const forgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState: {
    forgotPasswordFetching: false,
    forgotPasswordSuccess: false,
    forgotPasswordError: false,
    forgotPasswordErrorMessage: "",
  },
  reducers: {
    clearForgotPasswordState: (state) => {
      state.forgotPasswordError = false;
      state.forgotPasswordSuccess = false;
      state.forgotPasswordFetching = false;

      return state;
    },
  },
  extraReducers: {
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.forgotPasswordFetching = false;
      state.forgotPasswordSuccess = true;
      return state;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgotPasswordFetching = false;
      state.forgotPasswordError = true;
      state.forgotPasswordErrorMessage = action?.payload;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordFetching = true;
    },
  },
});

export const { clearForgotPasswordState } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
