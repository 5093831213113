import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const verifyOtp = createAsyncThunk(
  "admin/verify-otp",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.verifyOtpURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const verifyOtpSlice = createSlice({
  name: "verify-otp",
  initialState: {
    verifyOtpFetching: false,
    verifyOtpSuccess: false,
    verifyOtpError: false,
    verifyOtpErrorMessage: "",
    verifyData:{}
  },
  reducers: {
    clearVerifyOtpState: (state) => {
      state.verifyOtpError = false;
      state.verifyOtpSuccess = false;
      state.verifyOtpFetching = false;

      return state;
    },
  },
  extraReducers: {
    [verifyOtp.fulfilled]: (state, { payload }) => {
      state.verifyOtpFetching = false;
      state.verifyData = payload.data;
      state.verifyOtpSuccess = true;
      return state;
    },
    [verifyOtp.rejected]: (state, action) => {
      state.verifyOtpFetching = false;
      state.verifyOtpError = true;
      state.verifyOtpErrorMessage = action?.payload;
    },
    [verifyOtp.pending]: (state) => {
      state.verifyOtpFetching = true;
    },
  },
});

export const { clearVerifyOtpState } = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;
