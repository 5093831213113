import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchvehicleDocumentDetailsList = createAsyncThunk(
  "admin/ProjectDocument-details",
  async ({ vehicleID, documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.vehicleDocumentDetailsListURL
          .replace(":vehicleID", vehicleID)
          .replace(":id", documentID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const vehicleDocumentDetailsListSlice = createSlice({
  name: "ProjectDocument-details",
  initialState: {
    vehicleDocumentDetailsList: {},
    vehicleDocumentDetailsListFetching: false,
    vehicleDocumentDetailsListSuccess: false,
    vehicleDocumentDetailsListError: false,
    vehicleDocumentDetailsListErrorMessage: "",
  },
  reducers: {
    clearvehicleDocumentDetailsListState: (state) => {
      state.vehicleDocumentDetailsListError = false;
      state.vehicleDocumentDetailsListSuccess = false;
      state.vehicleDocumentDetailsListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchvehicleDocumentDetailsList.fulfilled]: (state, { payload }) => {
      state.vehicleDocumentDetailsList = payload.data;
      state.vehicleDocumentDetailsListFetching = false;
      state.vehicleDocumentDetailsListSuccess = true;
      return state;
    },
    [fetchvehicleDocumentDetailsList.rejected]: (state, action) => {
      state.vehicleDocumentDetailsListFetching = false;
      state.vehicleDocumentDetailsListError = true;
      state.vehicleDocumentDetailsListErrorMessage = action?.payload;
    },
    [fetchvehicleDocumentDetailsList.pending]: (state) => {
      state.vehicleDocumentDetailsListFetching = true;
    },
  },
});
export const { clearvehicleDocumentDetailsListState } =
  vehicleDocumentDetailsListSlice.actions;

export default vehicleDocumentDetailsListSlice.reducer;
