import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteProjectDocumentFile = createAsyncThunk(
  "admin/delete-ProjectDocumentFile",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectDocumentFileDeleteURL.replace("{id}", id),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const projectDocumentFileDeleteSlice = createSlice({
  name: "delete-ProjectDocumentFile",
  initialState: {
    deleteProjectDocumentFileFetching: false,
    deleteProjectDocumentFileSuccess: false,
    deleteProjectDocumentFileError: false,
    deleteProjectDocumentFileErrorMessage: "",
  },
  reducers: {
    deleteProjectDocumentFileClearState: (state) => {
      state.deleteProjectDocumentFileError = false;
      state.deleteProjectDocumentFileSuccess = false;
      state.deleteProjectDocumentFileFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteProjectDocumentFile.fulfilled]: (state, { payload }) => {
      state.deleteProjectDocumentFileFetching = false;
      state.deleteProjectDocumentFileSuccess = true;
      return state;
    },
    [deleteProjectDocumentFile.rejected]: (state, action) => {
      state.deleteProjectDocumentFileFetching = false;
      state.deleteProjectDocumentFileError = true;
      state.deleteProjectDocumentFileErrorMessage = action?.payload;
    },
    [deleteProjectDocumentFile.pending]: (state) => {
      state.deleteProjectDocumentFileFetching = true;
    },
  },
});

export const { deleteProjectDocumentFileClearState } =
  projectDocumentFileDeleteSlice.actions;

export default projectDocumentFileDeleteSlice.reducer;
