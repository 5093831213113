import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const resetEmployeePassword = createAsyncThunk(
  "admin/reset-employee-password",
  async ({payload, employeeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.resetEmployeePasswordURL.replace("{id}", employeeID),
        method: "POST",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const resetEmployeePasswordSlice = createSlice({
  name: "reset-employee-password",
  initialState: {
    resetEmployeePasswordFetching: false,
    resetEmployeePasswordSuccess: false,
    resetEmployeePasswordError: false,
    resetEmployeePasswordErrorMessage: "",
  },
  reducers: {
    clearResetEmployeePasswordState: (state) => {
      state.resetEmployeePasswordError = false;
      state.resetEmployeePasswordSuccess = false;
      state.resetEmployeePasswordFetching = false;
      return state;
    },
  },
  extraReducers: {
    [resetEmployeePassword.fulfilled]: (state, { payload }) => {
      state.resetEmployeePasswordFetching = false;
      state.resetEmployeePasswordSuccess = true;
      return state;
    },
    [resetEmployeePassword.rejected]: (state, action) => {
      state.resetEmployeePasswordFetching = false;
      state.resetEmployeePasswordError = true;
      state.resetEmployeePasswordErrorMessage = action?.payload;
    },
    [resetEmployeePassword.pending]: (state) => {
      state.resetEmployeePasswordFetching = true;
    },
  },
});
export const { clearResetEmployeePasswordState } = resetEmployeePasswordSlice.actions;

export default resetEmployeePasswordSlice.reducer;
