import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchFlexibleTimeScheduleDetails = createAsyncThunk(
  "admin/flexibleTimeSchedule-details",
  async ({ flexibleScheduleID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleTimeScheduleDetailsURL.replace("{id}", flexibleScheduleID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleTimeScheduleDetailsSlice = createSlice({
  name: "flexibleTimeSchedule-details",
  initialState: {
    flexibleTimeScheduleDetails: {},
    flexibleTimeScheduleDetailsFetching: false,
    flexibleTimeScheduleDetailsSuccess: false,
    flexibleTimeScheduleDetailsError: false,
    flexibleTimeScheduleDetailsErrorMessage: "",
  },
  reducers: {
    clearFlexibleTimeScheduleDetailsState: (state) => {
      state.flexibleTimeScheduleDetailsError = false;
      state.flexibleTimeScheduleDetailsSuccess = false;
      state.flexibleTimeScheduleDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFlexibleTimeScheduleDetails.fulfilled]: (state, { payload }) => {
      state.flexibleTimeScheduleDetails = payload.data;
      state.flexibleTimeScheduleDetailsFetching = false;
      state.flexibleTimeScheduleDetailsSuccess = true;
      return state;
    },
    [fetchFlexibleTimeScheduleDetails.rejected]: (state, action) => {
      state.flexibleTimeScheduleDetailsFetching = false;
      state.flexibleTimeScheduleDetailsError = true;
      state.flexibleTimeScheduleDetailsErrorMessage = action?.payload;
    },
    [fetchFlexibleTimeScheduleDetails.pending]: (state) => {
      state.flexibleTimeScheduleDetailsFetching = true;
    },
  },
});
export const { clearFlexibleTimeScheduleDetailsState } = flexibleTimeScheduleDetailsSlice.actions;

export default flexibleTimeScheduleDetailsSlice.reducer;
