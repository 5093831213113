import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchDepartmentDetails = createAsyncThunk(
  "admin/department-details",
  async ({ departmentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.departmentDetailsURL.replace("{id}", departmentID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const departmentDetailsSlice = createSlice({
  name: "department-details",
  initialState: {
    departmentDetails: {},
    departmentDetailsFetching: false,
    departmentDetailsSuccess: false,
    departmentDetailsError: false,
    departmentDetailsErrorMessage: "",
  },
  reducers: {
    clearDepartmentDetailsState: (state) => {
      state.departmentDetailsError = false;
      state.departmentDetailsSuccess = false;
      state.departmentDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDepartmentDetails.fulfilled]: (state, { payload }) => {
      state.departmentDetails = payload.data;
      state.departmentDetailsFetching = false;
      state.departmentDetailsSuccess = true;
      return state;
    },
    [fetchDepartmentDetails.rejected]: (state, action) => {
      state.departmentDetailsFetching = false;
      state.departmentDetailsError = true;
      state.departmentDetailsErrorMessage = action?.payload;
    },
    [fetchDepartmentDetails.pending]: (state) => {
      state.departmentDetailsFetching = true;
    },
  },
});
export const { clearDepartmentDetailsState } = departmentDetailsSlice.actions;

export default departmentDetailsSlice.reducer;
