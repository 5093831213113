import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchNormalTimeSchedulesList = createAsyncThunk(
  "admin/normalTimeSchedule-list",
  async ({limit ,offset ,search  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:api.normalTimeSchedulesListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const normalTimeScheduleListSlice = createSlice({
  name: "normalTimeSchedule-list",
  initialState: {
    normalTimeScheduleList: [],
    normalTimeScheduleListFetching: false,
    normalTimeScheduleListSuccess: false,
    normalTimeScheduleListError: false,
    normalTimeScheduleListErrorMessage: "",
    normalTimeScheduleCount:null,
  },
  reducers: {
    normalTimeScheduleListClearState: (state) => {
      state.normalTimeScheduleListError = false;
      state.normalTimeScheduleListSuccess = false;
      state.normalTimeScheduleListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchNormalTimeSchedulesList.fulfilled]: (state, { payload }) => {
      state.normalTimeScheduleList = [];
      payload.data.results.forEach((item) => state.normalTimeScheduleList.push(item));
      state.normalTimeScheduleCount = payload.data.count;
      state.normalTimeScheduleListFetching = false;
      state.normalTimeScheduleListSuccess = true;
      return state;
    },
    [fetchNormalTimeSchedulesList.rejected]: (state, action) => {
      state.normalTimeScheduleListFetching = false;
      state.normalTimeScheduleListError = true;
      state.normalTimeScheduleListErrorMessage = action?.payload;
    },
    [fetchNormalTimeSchedulesList.pending]: (state) => {
      state.normalTimeScheduleListFetching = true;
    },
  },
});

export const { normalTimeScheduleListClearState } = normalTimeScheduleListSlice.actions;

export default normalTimeScheduleListSlice.reducer;
