import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const staffCreate = createAsyncThunk(
  "admin/create-staff",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.staffPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const staffCreateSlice = createSlice({
  name: "create-staff",
  initialState: {
    staffCreateFetching: false,
    staffCreateSuccess: false,
    staffCreateError: false,
    staffCreateErrorMessage: "",
  },
  reducers: {
    clearCreateStaffState: (state) => {
      state.staffCreateError = false;
      state.staffCreateSuccess = false;
      state.staffCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [staffCreate.fulfilled]: (state, { payload }) => {
      state.staffCreateFetching = false;
      state.staffCreateSuccess = true;
      return state;
    },
    [staffCreate.rejected]: (state, action) => {
      state.staffCreateFetching = false;
      state.staffCreateError = true;
      state.staffCreateErrorMessage = action?.payload;
    },
    [staffCreate.pending]: (state) => {
      state.staffCreateFetching = true;
    },
  },
});

export const { clearCreateStaffState } = staffCreateSlice.actions;

export default staffCreateSlice.reducer;
