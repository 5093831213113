import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchOfficialDocumentDetails = createAsyncThunk(
  "admin/officialDocument-details",
  async ({ documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.officialDocumentDetailsURL.replace("{id}", documentID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const officialDocumentDetailsSlice = createSlice({
  name: "officialDocument-details",
  initialState: {
    officialDocumentDetails: {},
    officialDocumentDetailsFetching: false,
    officialDocumentDetailsSuccess: false,
    officialDocumentDetailsError: false,
    officialDocumentDetailsErrorMessage: "",
  },
  reducers: {
    clearOfficialDocumentDetailsState: (state) => {
      state.officialDocumentDetailsError = false;
      state.officialDocumentDetailsSuccess = false;
      state.officialDocumentDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchOfficialDocumentDetails.fulfilled]: (state, { payload }) => {
      state.officialDocumentDetails = payload.data;
      state.officialDocumentDetailsFetching = false;
      state.officialDocumentDetailsSuccess = true;
      return state;
    },
    [fetchOfficialDocumentDetails.rejected]: (state, action) => {
      state.officialDocumentDetailsFetching = false;
      state.officialDocumentDetailsError = true;
      state.officialDocumentDetailsErrorMessage = action?.payload;
    },
    [fetchOfficialDocumentDetails.pending]: (state) => {
      state.officialDocumentDetailsFetching = true;
    },
  },
});
export const { clearOfficialDocumentDetailsState } =
  officialDocumentDetailsSlice.actions;

export default officialDocumentDetailsSlice.reducer;
