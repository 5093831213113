import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchEmployeeIdDetails = createAsyncThunk(
  "admin/employee-id-details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeIdDetails,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeIDDetailsSlice = createSlice({
  name: "employee-id-details",
  initialState: {
    employeeIdDetails: {},
    employeeIdDetailsFetching: false,
    employeeIdDetailsSuccess: false,
    employeeIdDetailsError: false,
    employeeIdDetailsErrorMessage: "",
  },
  reducers: {
    clearAutogeneratedEmpIdState: (state) => {
      state.employeeIdDetailsError = false;
      state.employeeIdDetailsSuccess = false;
      state.employeeIdDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchEmployeeIdDetails.fulfilled]: (state, { payload }) => {
      state.employeeIdDetails = payload.data;
      state.employeeIdDetailsFetching = false;
      state.employeeIdDetailsSuccess = true;
      return state;
    },
    [fetchEmployeeIdDetails.rejected]: (state, action) => {
      state.employeeIdDetailsFetching = false;
      state.employeeIdDetailsError = true;
      state.employeeIdDetailsErrorMessage = action?.payload;
    },
    [fetchEmployeeIdDetails.pending]: (state) => {
      state.employeeIdDetailsFetching = true;
    },
  },
});
export const { clearAutogeneratedEmpIdState } = employeeIDDetailsSlice.actions;

export default employeeIDDetailsSlice.reducer;
