import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAttendanceReport = createAsyncThunk(
  "admin/attendance-report",
  async (
    { limit, offset, startDate, endDate, nationality, department, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url: api.attendanceReportURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{startDate}", startDate)
          .replace("{endDate}", endDate)
          .replace("{search}", search)
          .replace("{nationality}", nationality)
          .replace("{department}", department),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceReportSlice = createSlice({
  name: "attendance-report",
  initialState: {
    attendanceReport: [],
    attendanceReportFetching: false,
    attendanceReportSuccess: false,
    attendanceReportError: false,
    attendanceReportErrorMessage: "",
    attendanceCount: null,
  },
  reducers: {
    attendanceReportClearState: (state) => {
      state.attendanceReportError = false;
      state.attendanceReportSuccess = false;
      state.attendanceReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAttendanceReport.fulfilled]: (state, { payload }) => {
      state.attendanceReport = [];
      payload.data.results.forEach((item) => state.attendanceReport.push(item));
      state.attendanceCount = payload.data.count;
      state.attendanceReportFetching = false;
      state.attendanceReportSuccess = true;
      return state;
    },
    [fetchAttendanceReport.rejected]: (state, action) => {
      state.attendanceReportFetching = false;
      state.attendanceReportError = true;
      state.attendanceReportErrorMessage = action?.payload;
    },
    [fetchAttendanceReport.pending]: (state) => {
      state.attendanceReportFetching = true;
    },
  },
});

export const { attendanceReportClearState } = attendanceReportSlice.actions;

export default attendanceReportSlice.reducer;
