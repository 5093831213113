import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteSubscriptionPlan = createAsyncThunk(
  "admin/delete-subscriptionPlan",
  async ({ planID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subscriptionPlanDetailsURL.replace("{id}", planID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const subscriptionPlanDeleteSlice = createSlice({
  name: "delete-subscriptionPlan",
  initialState: {
    deleteSubscriptionPlanFetching: false,
    deleteSubscriptionPlanSuccess: false,
    deleteSubscriptionPlanError: false,
    deleteSubscriptionPlanErrorMessage: "",
  },
  reducers: {
    deleteSubscriptionPlanClearState: (state) => {
      state.deleteSubscriptionPlanError = false;
      state.deleteSubscriptionPlanSuccess = false;
      state.deleteSubscriptionPlanFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteSubscriptionPlan.fulfilled]: (state, { payload }) => {
      state.deleteSubscriptionPlanFetching = false;
      state.deleteSubscriptionPlanSuccess = true;
      return state;
    },
    [deleteSubscriptionPlan.rejected]: (state, action) => {
      state.deleteSubscriptionPlanFetching = false;
      state.deleteSubscriptionPlanError = true;
      state.deleteSubscriptionPlanErrorMessage = action?.payload;
    },
    [deleteSubscriptionPlan.pending]: (state) => {
      state.deleteSubscriptionPlanFetching = true;
    },
  },
});

export const { deleteSubscriptionPlanClearState } = subscriptionPlanDeleteSlice.actions;

export default subscriptionPlanDeleteSlice.reducer;
