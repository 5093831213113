import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return new Promise((resolve, reject) => {
    //Development
    payload.baseURL = "https://dev.enfono.com/api_taqmi/api/";
    //Production
    // payload.baseURL = "https://crm.taqmi.co/api_mistaff_backend/api/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
          localStorage.removeItem("upgrade");
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }

  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the staff
      localStorage.removeItem("admin-token");
      localStorage.removeItem("user-type");
      localStorage.removeItem("time-zone");
      window.location.pathname = "/auth/sign-in";
    }
    if (statusCode == 426 && err.config && !err.config.__isRetryRequest) {
      window.location.pathname = "/subscription-expired";
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  forgotPasswordURL: "auth/forgot_password/{id}/reset/", // [PUT]
  sendOtpURL: "auth/forgot_password/otp_send/", // [POST]
  verifyOtpURL: "auth/forgot_password/otp_verify/", // [POST]
  resetPasswordURL: "auth/password/reset/", // [POST]
  //Employee URLs

  //Dashboard
  expiringDocumentsURL: "company/dashboard/document-expired/",
  pendingLeavesListURL: "company/dashboard/pending-leave/",
  attendanceDataURL: "company/dashboard/today-attendance-graph/",
  presentTodayURL: "company/present-today/",
  absentTodayURL: "company/absent-today/",
  lateTodayURL: "company/late-today/",

  adminDashboardDataURL: "super_admin/dashboard/",

  //Common
  countyListURL: "company/countries/",
  adminCountryListURL: "super_admin/countries/",
  timezoneListURL: "super_admin/timezones/{id}/",

  //Employees
  employeesListURL:
    "company/employees/?limit={limit}&offset={offset}&search={search}",
  employeePostURL: "company/employees/",
  employeeDetailsURL: "company/employee/{id}/",
  autogenerateEmpIdURL: "company/id_auto_generate/",
  resetEmployeePasswordURL: "company/employee/{id}/password-reset",

  //Owner Company
  ownerCompanyListURL: "company/companies/",
  switchCompanyURL: "company/switch-company/{id}/",
  companyPermissionsURL: "company/subscription-features/",

  //Staff List
  staffsListURL:
    "company/staffs/?limit={limit}&offset={offset}&search={search}",
  staffPostURL: "company/staffs/",
  staffDetailsURL: "company/staff/{id}/",

  //Employee Documents
  employeeDocumentsListURL:
    "/company/employee/{id}/employee-documents/?limit={limit}&offset={offset}&search={search}&status={status}",
  employeeDocumentsPostURL: "/company/employee/{id}/employee-documents/",
  employeeDocumentsDetailsURL:
    "/company/employee/{empid}/employee-documents/{docid}/",
  employeeDocumentFileDeleteURL: "/company/employee-document/file/{id}/",

  //Official Documents
  officialDocumentsListURL:
    "company/company-documents/?limit={limit}&offset={offset}&search={search}&status={status}",
  officialDocumentsPostURL: "company/company-documents/",
  officialDocumentDetailsURL: "company/company-documents/{id}/",
  officialDocumentFileDeleteURL: "company/company-document/file/{id}/",

  //Attendance Reports
  attendanceReportURL:
    "company/monthly-attendance-report/?start_date={startDate}&end_date={endDate}&nationality={nationality}&department={department}&search={search}&limit={limit}&offset={offset}",
  monthlyAttendanceReportURL:
    "company/detail-attendance-report/?start_date={startDate}&end_date={endDate}&employee={employee}&nationality={nationality}&department={department}&search={search}&limit={limit}&offset={offset}",
  dailyAttendanceURL:
    "company/daily-attendance-report/?date={date}&nationality={nationality}&department={department}&search={search}&limit={limit}&offset={offset}",
  attendanceSummaryURL:
    "company/attendance-detail-summary/{id}/?start_date={startDate}&end_date={endDate}",
  attendanceDetailsURL: "company/attendance/{id}/",
  recalculateAttendanceURL: "company/attendance/{id}/recalculate/",
  lateReportURL:
    "company/late-report/?start_date={startDate}&end_date={endDate}&limit={limit}&offset={offset}",
  bulkAttendancePostURL: "company/attendance/bulk_create/",

  //QR Code
  qrCodeListURL: "company/location-qr-code/?limit={limit}&offset={offset}",
  qrCodePostURL: "company/location-qr-code/",
  qrCodeDetailsURL: "company/location-qr-code/{id}/",

  //Settings

  //Time Schedule
  normalTimeSchedulesListURL:
    "company/normal-time-schedules/?limit={limit}&offset={offset}&search={search}",
  normalTimeSchedulePostURL: "company/normal-time-schedules/",
  normalTimeScheduleDetailsURL: "company/normal-time-schedule/{id}/",

  flexibleTimeSchedulesListURL:
    "company/flexible-time-schedules/?limit={limit}&offset={offset}&search={search}",
  flexibleTimeSchedulePostURL: "company/flexible-time-schedules/",
  flexibleTimeScheduleDetailsURL: "company/flexible-time-schedule/{id}/",

  //Shifts
  normalShiftsListURL:
    "company/normal-shifts/?limit={limit}&offset={offset}&search={search}",
  normalShiftPostURL: "company/normal-shifts/",
  normalShiftDetailsURL: "company/normal-shift/{id}/",

  flexibleShiftsListURL:
    "company/flexible-shifts/?limit={limit}&offset={offset}&search={search}",
  flexibleShiftPostURL: "company/flexible-shifts/",
  flexibleShiftDetailsURL: "company/flexible-shift/{id}/",

  //Holiday
  holidaysListURL:
    "company/holidays/?limit={limit}&offset={offset}&search={search}",
  holidayPostURL: "company/holidays/",
  holidayDetailsURL: "company/holiday/{id}/",

  //Departments
  departmentsListURL:
    "company/departments/?limit={limit}&offset={offset}&search={search}",
  departmentPostURL: "company/departments/",
  departmentDetailsURL: "company/department/{id}/",

  //Employee Id
  employeeIdDetails: "company/employee_id_settings/",

  //Admin URLs

  //Owners
  ownersListURL:
    "super_admin/company-owners/?search={search}&limit={limit}&offset={offset}",
  ownerPostURL: "super_admin/company-owners/",
  ownerDetailsURL: "super_admin/company-owner/{id}/",

  //Users
  usersListURL:
    "super_admin/users/?limit={limit}&offset={offset}&search={search}",
  userPostURL: "super_admin/users/",
  userDetailsURL: "super_admin/user/{id}/",

  //Companies
  companiesListURL:
    "super_admin/company-owner/{id}/companies/?search={search}&limit={limit}&offset={offset}",
  companyPostURL: "super_admin/company-owner/{id}/companies/",
  companyDetailsURL: "super_admin/company/{id}/",

  //Company Subscriptions
  companySubscriptionsListURL:
    "super_admin/company/{id}/subscription/?limit={limit}&offset={offset}",
  companySubscriptionPostURL: "super_admin/company/{id}/subscription/",
  companySubscriptionDetailsURL:
    "super_admin/company/{company_id}/subscription/{id}/",

  //Subscription Plans
  subscriptionPlansListURL:
    "super_admin/subscription_plans/?limit={limit}&offset={offset}",
  subscriptionPlanPostURL: "super_admin/subscription_plans/",
  subscriptionPlanDetailsURL: "super_admin/subscription_plan/{id}/",

  //settings
  categoryListURL:
    "super_admin/company/categories/?limit={limit}&offset={offset}&search={search}",
  categoryPostURL: "super_admin/company/categories/",
  categoryDetailsURL: "super_admin/company/category/{id}/",

  //project documents
  projectDocumentListURL:
    "company/project-documents/?limit={limit}&offset={offset}&search={search}&status={statusID}",
  projectDocumentsPostURL: "/company/project-documents/",
  ProjectDocumentDetailsURL: "/company/project-documents/:id",
  ProjectDocumentDeleteURL: "company/project-documents/:id/",
  ProjectDocumentEditURL: "company/project-documents/:id/",
  projectDocumentFileDeleteURL: "company/project-document/file/{id}/",
  //vehicle Document
  vehicleDocumentListURL:
    "/company/vehicle/:id/vehicle-documents/?limit={limit}&offset={offset}&search={search}&status={statusID}",
  vehicleDocumentsPostURL: "/company/vehicle-and-documents/",
  vehicleDocumentDetailsListURL:
    "/company/vehicle/:vehicleID/vehicle-documents/:id/",
  vehicleListURL: "/company/vehicles/?search={search}",
  vehicleDocumentsUploadURL: "/company/vehicle/:id/vehicle-documents/",
  vehicleDocumentsEditURL: "/company/vehicle/:vehicleID/vehicle-documents/:id/",
  VehicleDocumentDeleteURL:
    "/company/vehicle/:vehicleID/vehicle-documents/:id/",
  vehicleDocumentFileDeleteURL: "/company/vehicle-document/file/{id}/",

  actionHandler,
};
