import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchFlexibleShiftsList = createAsyncThunk(
  "admin/flexibleShift-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleShiftsListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleShiftListSlice = createSlice({
  name: "flexibleShift-list",
  initialState: {
    flexibleShiftList: [],
    flexibleShiftListFetching: false,
    flexibleShiftListSuccess: false,
    flexibleShiftListError: false,
    flexibleShiftListErrorMessage: "",
    flexibleShiftCount:null,
  },
  reducers: {
    flexibleShiftListClearState: (state) => {
      state.flexibleShiftListError = false;
      state.flexibleShiftListSuccess = false;
      state.flexibleShiftListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFlexibleShiftsList.fulfilled]: (state, { payload }) => {
      state.flexibleShiftList = [];
      payload.data.results.forEach((item) => state.flexibleShiftList.push(item));
      state.flexibleShiftCount = payload.data.count;
      state.flexibleShiftListFetching = false;
      state.flexibleShiftListSuccess = true;
      return state;
    },
    [fetchFlexibleShiftsList.rejected]: (state, action) => {
      state.flexibleShiftListFetching = false;
      state.flexibleShiftListError = true;
      state.flexibleShiftListErrorMessage = action?.payload;
    },
    [fetchFlexibleShiftsList.pending]: (state) => {
      state.flexibleShiftListFetching = true;
    },
  },
});

export const { flexibleShiftListClearState } = flexibleShiftListSlice.actions;

export default flexibleShiftListSlice.reducer;
