import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const fetchEmployeeDocumentsList = createAsyncThunk(
  "admin/employeeDocument-list",
  async (
    { limit, offset, search, employeeID, status },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url: api.employeeDocumentsListURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{search}", search)
          .replace("{id}", employeeID)
          .replace("{status}", status),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeDocumentListSlice = createSlice({
  name: "employeeDocument-list",
  initialState: {
    employeeDocumentList: [],
    employeeDocumentListFetching: false,
    employeeDocumentListSuccess: false,
    employeeDocumentListError: false,
    employeeDocumentListErrorMessage: "",
    employeeDocumentCount: null,
  },
  reducers: {
    employeeDocumentListClearState: (state) => {
      state.employeeDocumentListError = false;
      state.employeeDocumentListSuccess = false;
      state.employeeDocumentListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchEmployeeDocumentsList.fulfilled]: (state, { payload }) => {
      state.employeeDocumentList = [];
      payload.data.results.forEach((item) =>
        state.employeeDocumentList.push(item)
      );
      state.employeeDocumentCount = payload.data.count;
      state.employeeDocumentListFetching = false;
      state.employeeDocumentListSuccess = true;
      return state;
    },
    [fetchEmployeeDocumentsList.rejected]: (state, action) => {
      state.employeeDocumentListFetching = false;
      state.employeeDocumentListError = true;
      state.employeeDocumentListErrorMessage = action?.payload;
    },
    [fetchEmployeeDocumentsList.pending]: (state) => {
      state.employeeDocumentListFetching = true;
    },
  },
});

export const { employeeDocumentListClearState } =
  employeeDocumentListSlice.actions;

export default employeeDocumentListSlice.reducer;
