import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editSubscriptionPlanDetails = createAsyncThunk(
  "admin/edit-subscriptionPlan",
  async ({payload, planID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subscriptionPlanDetailsURL.replace("{id}", planID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subscriptionPlanEditSlice = createSlice({
  name: "edit-subscriptionPlan",
  initialState: {
    editSubscriptionPlanFetching: false,
    editSubscriptionPlanSuccess: false,
    editSubscriptionPlanError: false,
    editSubscriptionPlanErrorMessage: "",
  },
  reducers: {
    clearEditSubscriptionPlanState: (state) => {
      state.editSubscriptionPlanError = false;
      state.editSubscriptionPlanSuccess = false;
      state.editSubscriptionPlanFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editSubscriptionPlanDetails.fulfilled]: (state, { payload }) => {
      state.editSubscriptionPlanFetching = false;
      state.editSubscriptionPlanSuccess = true;
      return state;
    },
    [editSubscriptionPlanDetails.rejected]: (state, action) => {
      state.editSubscriptionPlanFetching = false;
      state.editSubscriptionPlanError = true;
      state.editSubscriptionPlanErrorMessage = action?.payload;
    },
    [editSubscriptionPlanDetails.pending]: (state) => {
      state.editSubscriptionPlanFetching = true;
    },
  },
});
export const { clearEditSubscriptionPlanState } = subscriptionPlanEditSlice.actions;

export default subscriptionPlanEditSlice.reducer;
