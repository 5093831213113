import React, { memo, lazy, Suspense } from "react";

//router
import { Switch, Redirect } from "react-router";

import Loader from "../components/Loader";
//layoutpages
import Default from "../layouts/dashboard/default";

import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";
import Admin from "../layouts/dashboard/admin";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/dashboard/:reportType", component: Default },
  { path: "/", component: () => <Redirect to="/dashboard" /> },

  { path: "/employee", component: Default },
  { path: "/employee/:employeeID/employee-details", component: Default },
  { path: "/document-list", component: Default },
  { path: "/official-documents/:statusID", component: Default },
  { path: "/official-document/:documentID/details", component: Default },
  { path: "/attendance/:active_tab?", component: Default },
  { path: "/attendance-report/:employeeID/details", component: Default },
  { path: "/project-documents/:statusID", component: Default },
  {
    path: "/project-document/:documentID/details",
    component: Default,
  },
  {
    path: "/vehicle/:vehicleID/vehicle-document/:documentID/details",
    component: Default,
  },
  {
    path: "/employee-documents-list",
    component: Default,
  },
  {
    path: "/employee/:employeeID/employee-documents",
    component: Default,
  },
  {
    path: "/employee/:employeeID/employee-document/:documentID/details",
    component: Default,
  },
  {
    path: "/vehicle-documents",
    component: Default,
  },
  {
    path: "/vehicle/:vehicleID/vehicle-documents",
    component: Default,
  },
  {
    path: "/salarydetails/{id}",
    component: Default,
  },
  {
    path: "/salarymanagelist",
    component: Default,
  },
  {
    path: "/rewardslist",
    component: Default,
  },
  {
    path: "/rewardsdetails/{id}",
    component: Default,
  },
  {
    path: "/meetingminits",
    component: Default,
  },
  {
    path: "/sceduled-meeting",
    component: Default,
  },
  {
    path: "/upload-minits-list",
    component: Default,
  },
  {
    path: "/events-announcement",
    component: Default,
  },
  {
    path: "/events-lists",
    component: Default,
  },
  {
    path: "/events-details",
    component: Default,
  },
  {
    path: "/announcements-list",
    component: Default,
  },
  {
    path: "/announcements-details/{id}",
    component: Default,
  },
  {
    path: "/leave-apply-list",
    component: Default,
  },
  {
    path: "/company-owners",
    component: Default,
  },
  {
    path: "/subscription-expired",
    component: Default,
  },

  { path: "/qr-code", component: Default },
  { path: "/leave-details/{id}", component: Default },
  { path: "/complaint-registry", component: Default },
  { path: "/complaint-registry/{id}", component: Default },
  { path: "/user-list", component: Default },
  { path: "/department", component: Default },
  { path: "/errors/error404", component: Simple },
  { path: "/admin-dashboard", component: Admin },
  { path: "/owners", component: Admin },
  { path: "/owners/:ownerID/companies", component: Admin },
  {
    path: "/owners/:ownerID/companies/:companyID/:active_tab?",
    component: Admin,
  },
  { path: "/company-list", component: Admin },
  { path: "/branch-list", component: Admin },
  { path: "/admin-user-list", component: Admin },
  { path: "/subscription", component: Admin },
  { path: "/settings/:active_tab", component: Default },
  { path: "/cash-recieved/{id}", component: Admin },
  { path: "/license-expired/{id}", component: Admin },
  { path: "/branch-details/{id}", component: Admin },
  { path: "/user-details/{id}", component: Admin },
  { path: "/admin-settings/:active_tab?", component: Admin },
];

const authRoutes = [
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
