import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteCompany = createAsyncThunk(
  "admin/delete-company",
  async ({ companyID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companyDetailsURL.replace("{id}", companyID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const companyDeleteSlice = createSlice({
  name: "delete-company",
  initialState: {
    deleteCompanyFetching: false,
    deleteCompanySuccess: false,
    deleteCompanyError: false,
    deleteCompanyErrorMessage: "",
  },
  reducers: {
    deleteCompanyClearState: (state) => {
      state.deleteCompanyError = false;
      state.deleteCompanySuccess = false;
      state.deleteCompanyFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteCompany.fulfilled]: (state, { payload }) => {
      state.deleteCompanyFetching = false;
      state.deleteCompanySuccess = true;
      return state;
    },
    [deleteCompany.rejected]: (state, action) => {
      state.deleteCompanyFetching = false;
      state.deleteCompanyError = true;
      state.deleteCompanyErrorMessage = action?.payload;
    },
    [deleteCompany.pending]: (state) => {
      state.deleteCompanyFetching = true;
    },
  },
});

export const { deleteCompanyClearState } = companyDeleteSlice.actions;

export default companyDeleteSlice.reducer;
