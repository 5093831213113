import { memo, Fragment } from "react";

// header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

//sidebar
import AdminSidebar from "../../components/partials/dashboard/sidebarstyle/adminSidebar/adminSidebar";

//footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

//admin
import AdminRouter from "../../router/admin-router";


//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import {useHistory} from 'react-router-dom'
const Admin = memo((props) => {
  const history = useHistory()
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);

  var subHeader = "";
  var commanclass = "";

  return (
    <Fragment>
      <Loader />
      <AdminSidebar app_name={appName} />
      <main className={`main-content`} style={{backgroundColor:"#EAFBF8"}}>
        <div className={`${commanclass} position-relative `}>
          <Headerpro />
          {subHeader}
        </div>
        <div className={` ${pageLayout} content-inner pb-0 `}>
          <AdminRouter />
        </div>
        <Footer />
      </main>
      <SettingOffCanvas />
    </Fragment>
  );
});

Admin.displayName = "Admin";
export default Admin;
