import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const qrCodeCreate = createAsyncThunk(
  "admin/create-qrCode",
  async ({ payload  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.qrCodePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const qrCodeCreateSlice = createSlice({
  name: "create-qrCode",
  initialState: {
    qrCodeCreateFetching: false,
    qrCodeCreateSuccess: false,
    qrCodeCreateError: false,
    qrCodeCreateErrorMessage: "",
  },
  reducers: {
    clearCreateQrCodeState: (state) => {
      state.qrCodeCreateError = false;
      state.qrCodeCreateSuccess = false;
      state.qrCodeCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [qrCodeCreate.fulfilled]: (state, { payload }) => {
      state.qrCodeCreateFetching = false;
      state.qrCodeCreateSuccess = true;
      return state;
    },
    [qrCodeCreate.rejected]: (state, action) => {
      state.qrCodeCreateFetching = false;
      state.qrCodeCreateError = true;
      state.qrCodeCreateErrorMessage = action?.payload;
    },
    [qrCodeCreate.pending]: (state) => {
      state.qrCodeCreateFetching = true;
    },
  },
});

export const { clearCreateQrCodeState } = qrCodeCreateSlice.actions;

export default qrCodeCreateSlice.reducer;
