import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchvehiclesList = createAsyncThunk(
  "admin/vehicle-list",
  async ({limit ,offset ,search}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.vehicleListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const vehicleListSlice = createSlice({
  name: "vehicle-list",
  initialState: {
    vehicleList: [],
    vehicleListFetching: false,
    vehicleListSuccess: false,
    vehicleListError: false,
    vehicleListErrorMessage: "",
    vehicleCount:null,
  },
  reducers: {
    vehicleListClearState: (state) => {
      state.vehicleListError = false;
      state.vehicleListSuccess = false;
      state.vehicleListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchvehiclesList.fulfilled]: (state, { payload }) => {
      state.vehicleList = [];
      payload.data.results.forEach((item) => state.vehicleList.push(item));
      state.vehicleCount = payload.data.count;
      state.vehicleListFetching = false;
      state.vehicleListSuccess = true;
      return state;
    },
    [fetchvehiclesList.rejected]: (state, action) => {
      state.vehicleListFetching = false;
      state.vehicleListError = true;
      state.vehicleListErrorMessage = action?.payload;
    },
    [fetchvehiclesList.pending]: (state) => {
      state.vehicleListFetching = true;
    },
  },
});

export const { vehicleListClearState } = vehicleListSlice.actions;

export default vehicleListSlice.reducer;
