import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAdminCountryList = createAsyncThunk(
  "admin/adminCountry-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adminCountryListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adminCountryListSlice = createSlice({
  name: "adminCountry-list",
  initialState: {
    adminCountryList: [],
    adminCountryListFetching: false,
    adminCountryListSuccess: false,
    adminCountryListError: false,
    adminCountryListErrorMessage: "",
    adminCountryCount:null,
  },
  reducers: {
    adminCountryListClearState: (state) => {
      state.adminCountryListError = false;
      state.adminCountryListSuccess = false;
      state.adminCountryListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAdminCountryList.fulfilled]: (state, { payload }) => {
      state.adminCountryList = [];
      payload.data.forEach((item) => state.adminCountryList.push(item));
      state.adminCountryCount = payload.data.count;
      state.adminCountryListFetching = false;
      state.adminCountryListSuccess = true;
      return state;
    },
    [fetchAdminCountryList.rejected]: (state, action) => {
      state.adminCountryListFetching = false;
      state.adminCountryListError = true;
      state.adminCountryListErrorMessage = action?.payload;
    },
    [fetchAdminCountryList.pending]: (state) => {
      state.adminCountryListFetching = true;
    },
  },
});

export const { adminCountryListClearState } = adminCountryListSlice.actions;

export default adminCountryListSlice.reducer;
