import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const bulkAttendanceCreate = createAsyncThunk(
  "admin/create-bulk-attendance",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bulkAttendancePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const bulkAttendanceCreateSlice = createSlice({
  name: "create-bulk-attendance",
  initialState: {
    bulkAttendanceCreateFetching: false,
    bulkAttendanceCreateSuccess: false,
    bulkAttendanceCreateError: false,
    bulkAttendanceCreateErrorMessage: "",
  },
  reducers: {
    clearCreateAttendanceState: (state) => {
      state.bulkAttendanceCreateError = false;
      state.bulkAttendanceCreateSuccess = false;
      state.bulkAttendanceCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [bulkAttendanceCreate.fulfilled]: (state, { payload }) => {
      state.bulkAttendanceCreateFetching = false;
      state.bulkAttendanceCreateSuccess = true;
      return state;
    },
    [bulkAttendanceCreate.rejected]: (state, action) => {
      state.bulkAttendanceCreateFetching = false;
      state.bulkAttendanceCreateError = true;
      state.bulkAttendanceCreateErrorMessage = action?.payload;
    },
    [bulkAttendanceCreate.pending]: (state) => {
      state.bulkAttendanceCreateFetching = true;
    },
  },
});

export const { clearCreateAttendanceState } = bulkAttendanceCreateSlice.actions;

export default bulkAttendanceCreateSlice.reducer;
