import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteProjectDocument = createAsyncThunk(
  "admin/delete-ProjectDocument",
  async ({ documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.ProjectDocumentDeleteURL.replace(":id", documentID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const ProjectDocumentDeleteSlice = createSlice({
  name: "delete-ProjectDocument",
  initialState: {
    deleteProjectDocumentFetching: false,
    deleteProjectDocumentSuccess: false,
    deleteProjectDocumentError: false,
    deleteProjectDocumentErrorMessage: "",
  },
  reducers: {
    deleteProjectDocumentClearState: (state) => {
      state.deleteProjectDocumentError = false;
      state.deleteProjectDocumentSuccess = false;
      state.deleteProjectDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteProjectDocument.fulfilled]: (state, { payload }) => {
      state.deleteProjectDocumentFetching = false;
      state.deleteProjectDocumentSuccess = true;
      return state;
    },
    [deleteProjectDocument.rejected]: (state, action) => {
      state.deleteProjectDocumentFetching = false;
      state.deleteProjectDocumentError = true;
      state.deleteProjectDocumentErrorMessage = action?.payload;
    },
    [deleteProjectDocument.pending]: (state) => {
      state.deleteProjectDocumentFetching = true;
    },
  },
});

export const { deleteProjectDocumentClearState } = ProjectDocumentDeleteSlice.actions;

export default ProjectDocumentDeleteSlice.reducer;
