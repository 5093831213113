import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";


export const fetchCompanyList = createAsyncThunk(
  "admin/company-list",
  async ({limit ,offset ,search ,ownerID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companiesListURL.replace("{id}",ownerID).replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companyListSlice = createSlice({
  name: "company-list",
  initialState: {
    companyList: [],
    companyListFetching: false,
    companyListSuccess: false,
    companyListError: false,
    companyListErrorMessage: "",
    companyCount:null,
  },
  reducers: {
    companyListClearState: (state) => {
      state.companyListError = false;
      state.companyListSuccess = false;
      state.companyListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCompanyList.fulfilled]: (state, { payload }) => {
      state.companyList = [];
      payload.data.results.forEach((item) => state.companyList.push(item));
      state.companyCount = payload.data.count;
      state.companyListFetching = false;
      state.companyListSuccess = true;
      return state;
    },
    [fetchCompanyList.rejected]: (state, action) => {
      state.companyListFetching = false;
      state.companyListError = true;
      state.companyListErrorMessage = action?.payload;
    },
    [fetchCompanyList.pending]: (state) => {
      state.companyListFetching = true;
    },
  },
});

export const { companyListClearState } = companyListSlice.actions;

export default companyListSlice.reducer;
