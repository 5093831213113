import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchSubscriptionPlansList = createAsyncThunk(
  "admin/subscriptionPlan-list",
  async ({limit ,offset ,search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subscriptionPlansListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{search}",search),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subscriptionPlanListSlice = createSlice({
  name: "subscriptionPlan-list",
  initialState: {
    subscriptionPlanList: [],
    subscriptionPlanListFetching: false,
    subscriptionPlanListSuccess: false,
    subscriptionPlanListError: false,
    subscriptionPlanListErrorMessage: "",
    subscriptionPlanCount:null,
  },
  reducers: {
    subscriptionPlanListClearState: (state) => {
      state.subscriptionPlanListError = false;
      state.subscriptionPlanListSuccess = false;
      state.subscriptionPlanListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubscriptionPlansList.fulfilled]: (state, { payload }) => {
      state.subscriptionPlanList = [];
      payload.data.results.forEach((item) => state.subscriptionPlanList.push(item));
      state.subscriptionPlanCount = payload.data.count;
      state.subscriptionPlanListFetching = false;
      state.subscriptionPlanListSuccess = true;
      return state;
    },
    [fetchSubscriptionPlansList.rejected]: (state, action) => {
      state.subscriptionPlanListFetching = false;
      state.subscriptionPlanListError = true;
      state.subscriptionPlanListErrorMessage = action?.payload;
    },
    [fetchSubscriptionPlansList.pending]: (state) => {
      state.subscriptionPlanListFetching = true;
    },
  },
});

export const { subscriptionPlanListClearState } = subscriptionPlanListSlice.actions;

export default subscriptionPlanListSlice.reducer;
