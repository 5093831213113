import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const departmentCreate = createAsyncThunk(
  "admin/create-department",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.departmentPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const departmentCreateSlice = createSlice({
  name: "create-department",
  initialState: {
    departmentCreateFetching: false,
    departmentCreateSuccess: false,
    departmentCreateError: false,
    departmentCreateErrorMessage: "",
  },
  reducers: {
    clearDepartmentCreateState: (state) => {
      state.departmentCreateError = false;
      state.departmentCreateSuccess = false;
      state.departmentCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [departmentCreate.fulfilled]: (state, { payload }) => {
      state.departmentCreateFetching = false;
      state.departmentCreateSuccess = true;
      return state;
    },
    [departmentCreate.rejected]: (state, action) => {
      state.departmentCreateFetching = false;
      state.departmentCreateError = true;
      state.departmentCreateErrorMessage = action?.payload;
    },
    [departmentCreate.pending]: (state) => {
      state.departmentCreateFetching = true;
    },
  },
});

export const { clearDepartmentCreateState } = departmentCreateSlice.actions;

export default departmentCreateSlice.reducer;
