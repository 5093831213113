import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const employeeCreate = createAsyncThunk(
  "admin/create-employee",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.employeePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const employeeCreateSlice = createSlice({
  name: "create-employee",
  initialState: {
    employeeCreateFetching: false,
    employeeCreateSuccess: false,
    employeeCreateError: false,
    employeeCreateErrorMessage: "",
  },
  reducers: {
    clearCreateEmployeeState: (state) => {
      state.employeeCreateError = false;
      state.employeeCreateSuccess = false;
      state.employeeCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [employeeCreate.fulfilled]: (state, { payload }) => {
      state.employeeCreateFetching = false;
      state.employeeCreateSuccess = true;
      return state;
    },
    [employeeCreate.rejected]: (state, action) => {
      state.employeeCreateFetching = false;
      state.employeeCreateError = true;
      state.employeeCreateErrorMessage = action?.payload;
    },
    [employeeCreate.pending]: (state) => {
      state.employeeCreateFetching = true;
    },
  },
});

export const { clearCreateEmployeeState } = employeeCreateSlice.actions;

export default employeeCreateSlice.reducer;
