import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const AddVehicleAndUpload = createAsyncThunk(
  "admin/create-vehicleDocument",
  async ({ payload, vehicleID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.vehicleDocumentsUploadURL.replace(":id", vehicleID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const AddVehicleAndUploadSlice = createSlice({
  name: "create-vehicleDocument",
  initialState: {
    AddVehicleAndUploadFetching: false,
    AddVehicleAndUploadSuccess: false,
    AddVehicleAndUploadError: false,
    AddVehicleAndUploadErrorMessage: "",
  },
  reducers: {
    clearUploadvehicleDocumentState: (state) => {
      state.AddVehicleAndUploadError = false;
      state.AddVehicleAndUploadSuccess = false;
      state.AddVehicleAndUploadFetching = false;

      return state;
    },
  },
  extraReducers: {
    [AddVehicleAndUpload.fulfilled]: (state, { payload }) => {
      state.AddVehicleAndUploadFetching = false;
      state.AddVehicleAndUploadSuccess = true;
      return state;
    },
    [AddVehicleAndUpload.rejected]: (state, action) => {
      state.AddVehicleAndUploadFetching = false;
      state.AddVehicleAndUploadError = true;
      state.AddVehicleAndUploadErrorMessage = action?.payload;
    },
    [AddVehicleAndUpload.pending]: (state) => {
      state.AddVehicleAndUploadFetching = true;
    },
  },
});

export const { clearUploadvehicleDocumentState } =
  AddVehicleAndUploadSlice.actions;

export default AddVehicleAndUploadSlice.reducer;
