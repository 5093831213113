import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchHolidayDetails = createAsyncThunk(
  "admin/holiday-details",
  async ({ holidayID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.holidayDetailsURL.replace("{id}", holidayID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const holidayDetailsSlice = createSlice({
  name: "holiday-details",
  initialState: {
    holidayDetails: {},
    holidayDetailsFetching: false,
    holidayDetailsSuccess: false,
    holidayDetailsError: false,
    holidayDetailsErrorMessage: "",
  },
  reducers: {
    clearHolidayDetailsState: (state) => {
      state.holidayDetailsError = false;
      state.holidayDetailsSuccess = false;
      state.holidayDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchHolidayDetails.fulfilled]: (state, { payload }) => {
      state.holidayDetails = payload.data;
      state.holidayDetailsFetching = false;
      state.holidayDetailsSuccess = true;
      return state;
    },
    [fetchHolidayDetails.rejected]: (state, action) => {
      state.holidayDetailsFetching = false;
      state.holidayDetailsError = true;
      state.holidayDetailsErrorMessage = action?.payload;
    },
    [fetchHolidayDetails.pending]: (state) => {
      state.holidayDetailsFetching = true;
    },
  },
});
export const { clearHolidayDetailsState } = holidayDetailsSlice.actions;

export default holidayDetailsSlice.reducer;
