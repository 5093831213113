import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const resetPassword = createAsyncThunk(
  "admin/reset-password",
  async ({payload}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.resetPasswordURL,
        method: "POST",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const resetPasswordSlice = createSlice({
  name: "reset-password",
  initialState: {
    resetPasswordFetching: false,
    resetPasswordSuccess: false,
    resetPasswordError: false,
    resetPasswordErrorMessage: "",
  },
  reducers: {
    clearResetPasswordState: (state) => {
      state.resetPasswordError = false;
      state.resetPasswordSuccess = false;
      state.resetPasswordFetching = false;
      return state;
    },
  },
  extraReducers: {
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.resetPasswordFetching = false;
      state.resetPasswordSuccess = true;
      return state;
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetPasswordFetching = false;
      state.resetPasswordError = true;
      state.resetPasswordErrorMessage = action?.payload;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordFetching = true;
    },
  },
});
export const { clearResetPasswordState } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
