import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const deleteOfficialDocument = createAsyncThunk(
  "admin/delete-officialDocument",
  async ({ documentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.officialDocumentDetailsURL.replace("{id}", documentID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const officialDocumentDeleteSlice = createSlice({
  name: "delete-officialDocument",
  initialState: {
    deleteOfficialDocumentFetching: false,
    deleteOfficialDocumentSuccess: false,
    deleteOfficialDocumentError: false,
    deleteOfficialDocumentErrorMessage: "",
  },
  reducers: {
    deleteOfficialDocumentClearState: (state) => {
      state.deleteOfficialDocumentError = false;
      state.deleteOfficialDocumentSuccess = false;
      state.deleteOfficialDocumentFetching = false;
      return state;
    },
  },
  extraReducers: {
    [deleteOfficialDocument.fulfilled]: (state, { payload }) => {
      state.deleteOfficialDocumentFetching = false;
      state.deleteOfficialDocumentSuccess = true;
      return state;
    },
    [deleteOfficialDocument.rejected]: (state, action) => {
      state.deleteOfficialDocumentFetching = false;
      state.deleteOfficialDocumentError = true;
      state.deleteOfficialDocumentErrorMessage = action?.payload;
    },
    [deleteOfficialDocument.pending]: (state) => {
      state.deleteOfficialDocumentFetching = true;
    },
  },
});

export const { deleteOfficialDocumentClearState } = officialDocumentDeleteSlice.actions;

export default officialDocumentDeleteSlice.reducer;
