import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const flexibleShiftCreate = createAsyncThunk(
  "admin/create-flexibleShift",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.flexibleShiftPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const flexibleShiftCreateSlice = createSlice({
  name: "create-flexibleShift",
  initialState: {
    flexibleShiftCreateFetching: false,
    flexibleShiftCreateSuccess: false,
    flexibleShiftCreateError: false,
    flexibleShiftCreateErrorMessage: "",
  },
  reducers: {
    clearFlexibleCreateShiftState: (state) => {
      state.flexibleShiftCreateError = false;
      state.flexibleShiftCreateSuccess = false;
      state.flexibleShiftCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [flexibleShiftCreate.fulfilled]: (state, { payload }) => {
      state.flexibleShiftCreateFetching = false;
      state.flexibleShiftCreateSuccess = true;
      return state;
    },
    [flexibleShiftCreate.rejected]: (state, action) => {
      state.flexibleShiftCreateFetching = false;
      state.flexibleShiftCreateError = true;
      state.flexibleShiftCreateErrorMessage = action?.payload;
    },
    [flexibleShiftCreate.pending]: (state) => {
      state.flexibleShiftCreateFetching = true;
    },
  },
});

export const { clearFlexibleCreateShiftState } = flexibleShiftCreateSlice.actions;

export default flexibleShiftCreateSlice.reducer;
