import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const switchCompany = createAsyncThunk(
  "admin/switch-Company",
  async ({payload, companyID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.switchCompanyURL.replace("{id}", companyID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const switchCompanySlice = createSlice({
  name: "switch-Company",
  initialState: {
    switchCompanyFetching: false,
    switchCompanySuccess: false,
    switchCompanyError: false,
    switchCompanyErrorMessage: "",
  },
  reducers: {
    clearSwitchCompanyState: (state) => {
      state.switchCompanyError = false;
      state.switchCompanySuccess = false;
      state.switchCompanyFetching = false;
      return state;
    },
  },
  extraReducers: {
    [switchCompany.fulfilled]: (state, { payload }) => {
      state.switchCompanyFetching = false;
      state.switchCompanySuccess = true;
      return state;
    },
    [switchCompany.rejected]: (state, action) => {
      state.switchCompanyFetching = false;
      state.switchCompanyError = true;
      state.switchCompanyErrorMessage = action?.payload;
    },
    [switchCompany.pending]: (state) => {
      state.switchCompanyFetching = true;
    },
  },
});
export const { clearSwitchCompanyState } = switchCompanySlice.actions;

export default switchCompanySlice.reducer;
