import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchCompanySubscriptionList = createAsyncThunk(
  "admin/companySubscription-list",
  async ({limit ,offset ,companyID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.companySubscriptionsListURL.replace("{id}",companyID).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const companySubscriptionListSlice = createSlice({
  name: "companySubscription-list",
  initialState: {
    companySubscriptionList: [],
    companySubscriptionListFetching: false,
    companySubscriptionListSuccess: false,
    companySubscriptionListError: false,
    companySubscriptionListErrorMessage: "",
    companySubscriptionCount:null,
  },
  reducers: {
    companySubscriptionListClearState: (state) => {
      state.companySubscriptionListError = false;
      state.companySubscriptionListSuccess = false;
      state.companySubscriptionListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCompanySubscriptionList.fulfilled]: (state, { payload }) => {
      state.companySubscriptionList = [];
      payload.data.results.forEach((item) => state.companySubscriptionList.push(item));
      state.companySubscriptionCount = payload.data.count;
      state.companySubscriptionListFetching = false;
      state.companySubscriptionListSuccess = true;
      return state;
    },
    [fetchCompanySubscriptionList.rejected]: (state, action) => {
      state.companySubscriptionListFetching = false;
      state.companySubscriptionListError = true;
      state.companySubscriptionListErrorMessage = action?.payload;
    },
    [fetchCompanySubscriptionList.pending]: (state) => {
      state.companySubscriptionListFetching = true;
    },
  },
});

export const { companySubscriptionListClearState } = companySubscriptionListSlice.actions;

export default companySubscriptionListSlice.reducer;
