import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editQrCodeDetails = createAsyncThunk(
  "admin/edit-qrCode",
  async ({payload, qrCodeID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.qrCodeDetailsURL.replace("{id}", qrCodeID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const qrCodeEditSlice = createSlice({
  name: "edit-qrCode",
  initialState: {
    editQrCodeFetching: false,
    editQrCodeSuccess: false,
    editQrCodeError: false,
    editQrCodeErrorMessage: "",
  },
  reducers: {
    clearEditQrCodeState: (state) => {
      state.editQrCodeError = false;
      state.editQrCodeSuccess = false;
      state.editQrCodeFetching = false;
      return state;
    },
  },
  extraReducers: {
    [editQrCodeDetails.fulfilled]: (state, { payload }) => {
      state.editQrCodeFetching = false;
      state.editQrCodeSuccess = true;
      return state;
    },
    [editQrCodeDetails.rejected]: (state, action) => {
      state.editQrCodeFetching = false;
      state.editQrCodeError = true;
      state.editQrCodeErrorMessage = action?.payload;
    },
    [editQrCodeDetails.pending]: (state) => {
      state.editQrCodeFetching = true;
    },
  },
});
export const { clearEditQrCodeState } = qrCodeEditSlice.actions;

export default qrCodeEditSlice.reducer;
